import { Avatar, Image, Menu, Text, Title } from "@mantine/core";
import { IconChevronDown, IconSparkles } from "@tabler/icons-react";
import { track } from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { InspoBadge } from "./ui/InspoBadge";
import { InspoButtonUnderline } from "./ui/InspoButtonUnderline";
import { InspoButton } from "./ui/InspoButton";
import { useUser } from "../contexts/UserContext";
import { useIsMobile } from "../hooks/useIsMobile";
import { openModalUpdates } from "./ModalUpdates";
import { openModalSaved } from "./savedModal/ModalSaved";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";

export const AppHeader = ({}: {}) => {
  const isMobile = useIsMobile();
  const { user, isPro } = useUser();

  return (
    <Horizontal
      fullW
      h={isMobile ? 52 : 72}
      center={isMobile}
      noWrap
      style={{ justifyContent: "space-between" }}
    >
      {isMobile && (
        <Horizontal gap={"xs"} noWrap>
          <Title variant="headline-xs" fz={24} c={"white"}>
            inspotype
          </Title>
          <InspoBadge theme="light">MINI</InspoBadge>
        </Horizontal>
      )}
      {!isMobile && (
        <Title variant="headline-sm" fz={24}>
          inspotype
        </Title>
      )}

      <Horizontal gap={"lg"} noWrap>
        {!isMobile && (
          <InspoButtonUnderline
            onClick={() => {
              track({
                event: "open_newsletter_modal",
                properties: { source: "header" },
              });

              openModalUpdates({ isMobile });
            }}
          >
            Newsletter
          </InspoButtonUnderline>
        )}

        {!isPro && (
          <InspoButton
            theme="accent"
            iconPos="right"
            icon={<IconSparkles />}
            onClick={() => {
              openModalLifetimeIfNeeded({
                user,
                isPro,
                source: "cta",
              });
            }}
          >
            $97 Lifetime Access
          </InspoButton>
        )}

        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            <Horizontal gap={"xs"} style={{ cursor: "pointer" }} noWrap>
              {user && (
                <Avatar size={24}>
                  <Image
                    referrerPolicy="no-referrer"
                    src={user.profile_image_url}
                  ></Image>
                </Avatar>
              )}
              {!isMobile && (
                <Text variant="paragraph-lg">
                  {user ? user.full_name : "Login"}
                </Text>
              )}
              {!!user && isPro === true && (
                <InspoBadge theme="purple">PRO</InspoBadge>
              )}
              {user && (
                <IconChevronDown size={20} color={isMobile ? "white" : ""} />
              )}
            </Horizontal>
          </Menu.Target>
          <Menu.Dropdown>
            {!user && (
              <form
                action={`/api/auth/signin?signinOrigin=${isMobile ? "mobile" : "desktop"}`}
                method="POST"
              >
                <Menu.Item component="button" type="submit">
                  Login with Google
                </Menu.Item>
              </form>
            )}

            {user && (
              <>
                <Menu.Item
                  onClick={() =>
                    openModalSaved({
                      step: undefined,
                      shareUrl: undefined,
                    })
                  }
                >
                  Saved
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    track({
                      event: "signout",
                      properties: {},
                    });
                    // @ts-ignore
                    window.posthog?.reset();
                    window.location.href = "/api/auth/signout";
                  }}
                >
                  Logout
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Horizontal>
    </Horizontal>
  );
};
