import { Title, Image, AspectRatio } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";

type TitleWithBgColorContentProps = {
  title: string;
};

export const titleWithBgColorContent = {
  title1: {
    title: "Chroma Couture",
  },
  title2: {
    title: "Dispatch Daily",
  },
  title3: {
    title: "Capital Crush",
  },
  title4: {
    title: "Savor Spire",
  },
  title5: {
    title: "Velocity Vibe",
  },
  title6: {
    title: "Glamour Glow",
  },
} as const satisfies Record<string, TitleWithBgColorContentProps>;

export default function TitleWithBgColor({
  colorScheme,
  content,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<TitleWithBgColorContentProps> & BentoCardChildrenProps & {
  uploadedImagePath?: string;
}) {
  if (!content) return null;

  return (
    <Vertical fullW fullH center bg={colorScheme.background} {...props}>
      {uploadedImagePath ? (
        <Image
          src={uploadedImagePath}
          alt="Uploaded image"
          radius={"xxs"}
          h={"70%"}
          maw={"90%"}
          fit="contain"
        />
      ) : (
        <Title variant="card-headline-sm" c={colorScheme.textdark}>
          {content.title}
        </Title>
      )}
    </Vertical>
  );
}
