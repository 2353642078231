import { useHotkeys, useLocalStorage, useSessionStorage } from "@mantine/hooks";
import { createContext, useContext, useState } from "react";
import { openModalLoginIfNeeded } from "../components/ModalLogin";
import { useUser } from "./UserContext";
import { InspoEvent, track } from "../utils";
import { colorSchemes } from "src/utils/colorScheme/palette";

interface ColorSchemeContextType {
  colorSchemeIndex: number;
  setColorSchemeIndex: (index: number) => void;
  customColorSchemeIsActive: boolean;
  setCustomColorSchemeIsActive: (isActive: boolean) => void;
  customColorScheme: any;
  setCustomColorScheme: (scheme: any) => void;
  originColorScheme: any;
  setOriginColorScheme: (scheme: any) => void;
  currentColorScheme: any;
  colorIsLockedAtSlug: string | undefined;
  setColorIsLockedAtSlug: (slug: string) => void;
  handleColorSchemeChange: (change: "back" | "next" | "random") => void;
}

const ColorSchemeContext = createContext<ColorSchemeContextType | undefined>(
  undefined,
);

export function ColorSchemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();

  const [colorSchemeIndex, setColorSchemeIndex] = useLocalStorage({
    key: "color_scheme_index",
    defaultValue: 0,
    getInitialValueInEffect: true,
  });
  const [customColorSchemeIsActive, setCustomColorSchemeIsActive] =
    useSessionStorage({
      key: "custom_color_scheme_is_active",
      defaultValue: false,
    });
  const [customColorScheme, setCustomColorScheme] = useSessionStorage<
    any | undefined
  >({
    key: "custom_color_scheme",
    defaultValue: undefined,
  });
  const [originColorScheme, setOriginColorScheme] = useSessionStorage<
    any | undefined
  >({
    key: "origin_color_scheme",
    defaultValue: undefined,
  });

  useHotkeys([
    [
      "ArrowUp",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowup",
          })
        ) {
          return;
        }
        track({
          event: "change_color",
          properties: { source: "keyboard", action: "back" },
        });
        handleColorSchemeChange("next");
      },
    ],
    [
      "ArrowDown",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowdown",
          })
        ) {
          return;
        }
        track({
          event: "change_color",
          properties: { source: "keyboard", action: "next" },
        });
        handleColorSchemeChange("back");
      },
    ],
  ]);

  const [colorIsLockedAtSlug, setColorIsLockedAtSlug] = useState<
    string | undefined
  >(undefined);

  function innerSetColorSchemeAtSlug(slug: string) {
    if (slug) {
      const index = colorSchemes.findIndex((c) => c.slug === slug);
      setColorSchemeIndex(index !== -1 ? index : 0);
    }
    setColorIsLockedAtSlug(slug);
  }

  const currentColorScheme =
    customColorSchemeIsActive && !!customColorScheme
      ? customColorScheme
      : colorSchemes[colorSchemeIndex];

  function handleColorSchemeChange(change: "back" | "next" | "random") {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "color.change",
      })
    ) {
      return;
    }

    if (colorIsLockedAtSlug !== undefined || customColorSchemeIsActive) return;

    let newIndex;
    switch (change) {
      case "back":
        newIndex =
          colorSchemeIndex === 0
            ? colorSchemes.length - 1
            : colorSchemeIndex - 1;
        break;
      case "next":
        newIndex =
          colorSchemeIndex === colorSchemes.length - 1
            ? 0
            : colorSchemeIndex + 1;
        break;
      case "random":
        newIndex = Math.floor(Math.random() * colorSchemes.length);
        break;
    }

    setColorSchemeIndex(newIndex);
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }

  return (
    <ColorSchemeContext.Provider
      value={{
        colorSchemeIndex,
        setColorSchemeIndex,
        customColorSchemeIsActive,
        setCustomColorSchemeIsActive,
        customColorScheme,
        setCustomColorScheme,
        originColorScheme,
        setOriginColorScheme,
        currentColorScheme,
        colorIsLockedAtSlug,
        setColorIsLockedAtSlug: innerSetColorSchemeAtSlug,
        handleColorSchemeChange,
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
}

export function useColorPalette() {
  const context = useContext(ColorSchemeContext);
  if (context === undefined) {
    throw new Error("useColorScheme must be used within a ColorSchemeProvider");
  }
  return context;
}
