import { UnstyledButton, type UnstyledButtonProps } from "@mantine/core";
import style from "./../../../../styles/CustomComponents.module.scss";

export function InspoPickerListButton({
  selected,
  onClick,
  children,
  ...props
}: {
  selected?: boolean;
  onClick?: (e: any) => void;
  children?: React.ReactNode;
} & UnstyledButtonProps) {
  return (
    <UnstyledButton
      data-list-item
      display="block"
      px={"xs"}
      py={"xs"}
      w={"100%"}
      classNames={{ root: style.inspoPickerListButtonRoot }}
      bg={selected ? "white" : undefined}
      c={selected ? "black" : undefined}
      onClick={(e) => {
        onClick?.(e);
      }}
      {...props}
    >
      {children}
    </UnstyledButton>
  );
}
