import { ActionIcon, type ActionIconProps } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React from "react";
import {
  Vertical,
  type VerticalProps,
} from "../mantine-layout-components/Vertical";

export const InspoToolbarActionIcon = React.forwardRef(
  (
    {
      show = false,
      icon,
      onClick = () => {},
      wrapperProps = {},
      buttonProps = {},
    }: {
      show: boolean;
      icon: React.ReactNode;
      onClick?: () => void;
      wrapperProps?: VerticalProps;
      buttonProps?: ActionIconProps;
    },
    ref,
  ) => {
    const { hovered: hovered, ref: refHover } = useHover();

    return (
      <Vertical h={36} w={36} ref={refHover as any} center {...wrapperProps}>
        {show && (
          <ActionIcon
            // @ts-ignore
            ref={ref}
            variant="transparent"
            h={36}
            w={36}
            bg={hovered ? "gray.2" : ""}
            c={"black"}
            radius={"xxs"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              onClick?.();
            }}
            {...buttonProps}
          >
            {/* @ts-ignore */}
            {React.cloneElement(icon, { size: 16 })}
          </ActionIcon>
        )}
      </Vertical>
    );
  },
);
