import { Badge, type BadgeProps } from "@mantine/core";

const badgeThemes = {
  purple: {
    color: "purple",
    bg: "rgba(197, 176, 255, 1)",
  },
  light: {
    color: "rgba(139, 139, 139, 1)",
    bg: "rgba(246, 246, 246, 1)",
  },
  dark: {
    color: "gray.4",
    bg: "gray.9",
  },
  lime: {
    color: "lime700",
    bg: "lime300",
  },
  sky: {
    color: "sky700",
    bg: "sky200",
  },
};

type BadgeThemeType = keyof typeof badgeThemes;

export function InspoBadge({
  theme,
  children,
  ...props
}: {
  theme: BadgeThemeType;
  children: React.ReactNode;
} & BadgeProps) {
  return (
    <Badge
      variant="label-sm"
      bg={badgeThemes[theme].bg}
      c={badgeThemes[theme].color}
      radius={"xxs"}
      px={"xs"}
      py={"xs"}
      h={15}
      mt={2}
      {...props}
    >
      {children}
    </Badge>
  );
}
