import { Text, Title } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";

type HeadingWithBgColorContentProps = {
  title: string;
  subtitle: string;
  labelBelowTitle: string;
};

export const headingWithBgColorContent = {
  heading1: {
    title: "Mediterraneo",
    subtitle: "A classic combination of chicken and mushrooms.",
    labelBelowTitle: "$18",
  },
  heading2: {
    title: "Vitamin C Serum",
    subtitle:
      "Vegan ingredients to hydrate, brighten and protect the delicate & sensitive skin.",
    labelBelowTitle: "$9",
  },
} as const satisfies Record<string, HeadingWithBgColorContentProps>;

export default function HeadingWithBgColor({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<HeadingWithBgColorContentProps> &
  BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      center
      px="md"
      gap={"xs"}
      bg={colorScheme.background}
      {...props}
    >
      <Title variant="card-headline-xs" c={colorScheme.textdark} ta={"center"}>
        {content.title}
      </Title>

      <Text variant="card-paragraph-sm" c={colorScheme.textdark} ta={"center"}>
        {content.subtitle}
      </Text>

      <Text variant="card-label-lg" c={colorScheme.textdark} ta={"center"}>
        {content.labelBelowTitle}
      </Text>
    </Vertical>
  );
}
