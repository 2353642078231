import { useViewportSize } from "@mantine/hooks";

export function useIsMobile() {
  const { width } = useViewportSize();

  if (width === 0) return false;

  const isMobile = width < 800;

  return isMobile;
}
