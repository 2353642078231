import { ActionIcon, ScrollArea, Text, Title } from "@mantine/core";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { IconEdit, IconX } from "@tabler/icons-react";
import { Vertical } from "../mantine-layout-components/Vertical";
import { InspoButton } from "../ui/InspoButton";
import type { SavedFolder } from "./ModalSaved";

export function FoldersModalContent({
  folders,
  handleClose,
  handleGoToFolder,
  handleGoToEditFolder,
  handleGoToCreateNewFolder,
}: {
  folders: SavedFolder[];
  handleClose: () => void;
  handleGoToFolder: (id: string) => void;
  handleGoToEditFolder: (id: string) => void;
  handleGoToCreateNewFolder: () => void;
}) {
  const isEmpty = folders.length === 0;

  return (
    <>
      <Horizontal fullW mb="md" center>
        <Text variant="paragraph-lg">Saved</Text>
        <ActionIcon
          onClick={handleClose}
          pos={"absolute"}
          right={24}
          size={48}
          variant="subtle"
          tabIndex={-1}
        >
          <IconX size={24} />
        </ActionIcon>
      </Horizontal>

      {isEmpty && (
        <Vertical gap={"sm"} fullW center mb={"lg"}>
          <Text variant="paragraph-xl" ta="center" c="dimmed" mt="md">
            No folders yet
          </Text>
          <Text variant="paragraph-md" ta="center" c="dimmed">
            Create a new folder to save your moodboards.
          </Text>
        </Vertical>
      )}

      {!isEmpty && (
        <ScrollArea h={400} mb="lg">
          <Vertical fullW gap="xxs">
            {folders.map((folder) => (
              <Horizontal
                bg={"white"}
                key={folder.id}
                fullW
                spaceBetween
                p="md"
                style={{
                  borderRadius: "var(--mantine-radius-sm)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleGoToFolder(folder.id);
                }}
              >
                <Vertical gap={"xxs"}>
                  <Title variant="headline-sm">{folder.name}</Title>
                  <Text variant="label-md" c={"dimmed"}>
                    {folder.moodboards.length} moodboard
                    {folder.moodboards.length > 1 ||
                    folder.moodboards.length === 0
                      ? "s"
                      : ""}
                  </Text>
                </Vertical>
                <ActionIcon
                  size={48}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleGoToEditFolder(folder.id);
                  }}
                  variant="subtle"
                >
                  <IconEdit size={24} />
                </ActionIcon>
              </Horizontal>
            ))}
          </Vertical>
        </ScrollArea>
      )}

      <InspoButton
        pos="sticky"
        theme="dark"
        fullWidth
        onClick={handleGoToCreateNewFolder}
      >
        Create new folder
      </InspoButton>
    </>
  );
}
