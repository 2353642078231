import type { APIRoute } from "astro";

// export const prerender = false;

export const keyCreateEmailContact = `/api/subscribe_to_newsletter`;

export const POST: APIRoute = async ({ request, cookies, redirect }) => {
  const body = await request.json();

  try {
    //https://emailoctopus.com/api-documentation/lists/create-contact
    const response = await fetch(
      "https://emailoctopus.com/api/1.6/lists/f40a38f2-9910-11ee-9ae7-0de6ac9df727/contacts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          api_key: import.meta.env.EMAILOCTOPUS_API_KEY,
          email_address: body.email,
          tags: [body.origin],
        }),
      },
    );

    if (!response.ok) {
      console.log(await response.json());
      throw new Error("Failed to create contact");
    }
    return new Response(JSON.stringify({}), { status: 201 });
  } catch (error) {
    console.log(error);
    return new Response(error.message, { status: 500 });
  }
};
