import { AspectRatio, Image, Space, Text, Title } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";

type EcommItemContentProps = {
  title: string;
  subtitle: string;
  subtitle2?: string;
  bottomSubtitle?: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const ecommItemContent = {
  ecomm1: {
    title: "Crazy Flight MVP Men's Tank",
    subtitle: "T-shirt",
    subtitle2: "2 Colors",
    bottomSubtitle: "$59",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, EcommItemContentProps>;

export default function EcommItem({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<EcommItemContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical fullW fullH center bg={colorScheme.background} px="md" {...props}>
      <Horizontal noWrap fullW>
        <AspectRatio ratio={1 / 1} mah={200} maw={200}>
          <Image
            src={uploadedImagePath ?? content.imgs[imgIndex]}
            alt={content.alt}
            radius={"xxs"}
            fit="cover"
          />
        </AspectRatio>

        <Vertical gap={0}>
          <Title variant="card-headline-xs" c={colorScheme.textdark}>
            {content.title}
          </Title>

          <Space h={"xs"} />

          <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
            {content.subtitle}
          </Text>

          <Space h={"xxs"} />

          <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
            {content.subtitle2}
          </Text>

          <Space h={"md"} />

          <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
            {content.bottomSubtitle}
          </Text>
        </Vertical>
      </Horizontal>
    </Vertical>
  );
}
