import { IconAdjustments, IconDiamond } from "@tabler/icons-react";
import { track } from "../utils";
import { ColorSchemePickerTabCurated } from "./ColorSchemePickerTabCurated";
import { ColorSchemePickerTabCustom } from "./ColorSchemePickerTabCustom";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoPickerTabButton } from "./ui/InspoPickerTabButton";
import { useUser } from "../contexts/UserContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";

export const ColorSchemePicker = ({
  tab,
  setTab,
  setShow,
}: {
  tab: "curated" | "custom";
  setTab: (value: "curated" | "custom") => void;
  setShow: (value: boolean) => void;
}) => {
  const { isPro } = useUser();
  const { customColorScheme, originColorScheme, setCustomColorSchemeIsActive } =
    useColorPalette();

  return (
    <Vertical
      id="color-scheme-picker"
      h={580}
      w={
        { curated: 400, custom: 600 }[
          !customColorScheme || !originColorScheme ? "curated" : tab
        ]
      }
      pos={"absolute"}
      bottom={70}
      ml={
        { curated: -125, custom: -250 }[
          !customColorScheme || !originColorScheme ? "curated" : tab
        ]
      }
      p={"sm"}
      gap={"sm"}
      bg={"#323232"}
      style={{
        boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.20)",
        borderRadius: "var(--mantine-radius-md)",
        border: !isPro ? undefined : `2px solid #474444`,
        backdropFilter: "blur(15px)",
      }}
    >
      <Horizontal noWrap gap={"xxs"} center fullW>
        <InspoPickerTabButton
          title="curated"
          icon={<IconDiamond />}
          selected={tab === "curated"}
          onClick={() => {
            track({
              event: "change_color_scheme_picker_tab",
              properties: { tab: "curated" },
            });
            setTab("curated");
            setCustomColorSchemeIsActive(false);
          }}
        />
        <InspoPickerTabButton
          title="custom"
          icon={<IconAdjustments />}
          selected={tab === "custom"}
          onClick={() => {
            track({
              event: "change_color_scheme_picker_tab",
              properties: { tab: "custom" },
            });
            setTab("custom");
            setCustomColorSchemeIsActive(true);
          }}
        />
      </Horizontal>

      {tab === "curated" && (
        <ColorSchemePickerTabCurated setTab={setTab} setShow={setShow} />
      )}

      {tab === "custom" && <ColorSchemePickerTabCustom setTab={setTab} />}
    </Vertical>
  );
};
