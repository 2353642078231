import { Text, TextInput } from "@mantine/core";
import { useValidatedState } from "@mantine/hooks";
import { useState } from "react";
import { keyCreateEmailContact } from "src/pages/api/subscribe_to_newsletter";
import { track } from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoButton } from "./ui/InspoButton";

export const EmailInput = ({ onClick }: { onClick?: () => void }) => {
  const [shouldShowThankYouMessage, setShouldShowThankYouMessage] =
    useState(false);

  const [{ value: emailValue, lastValidValue, valid }, setEmailValue] =
    useValidatedState("", (val) => /^\S+@\S+$/.test(val), false);

  if (shouldShowThankYouMessage) {
    return (
      <Vertical h={60} center>
        <Text c={"rgba(28, 27, 31, 0.70)"} variant="paragraph-md">
          Great! We&apos;ll meet you later in your inbox ♥️
        </Text>
      </Vertical>
    );
  }

  return (
    <Horizontal
      noWrap
      bg={"white"}
      py={"xs"}
      px={"xs"}
      maw={"400px"}
      style={{
        borderRadius: "var(--mantine-radius-sm)",
      }}
      w={"100%"}
    >
      <TextInput
        variant="unstyled"
        placeholder="Your email"
        ml={"xs"}
        mx={"auto"}
        type="email"
        autoFocus
        value={emailValue}
        onChange={(event) => setEmailValue(event.currentTarget.value)}
        styles={{
          wrapper: {
            border: "0px",
          },
        }}
      />
      <InspoButton
        theme="dark"
        radius={"xs"}
        onClick={async () => {
          setShouldShowThankYouMessage(true);
          await fetch(keyCreateEmailContact, {
            body: JSON.stringify({
              origin: "update",
              email: emailValue,
            }),
            method: "POST",
          });

          onClick?.();

          track({
            event: "subscribe_newsletter",
            properties: { origin: origin },
          });
        }}
      >
        Keep me updated
      </InspoButton>
    </Horizontal>
  );
};
