import { Button, Text } from "@mantine/core";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

export function InspoConfirmDeleteButton({
  onDelete,
  variant = "light",
}: {
  onDelete: () => void;
  variant?: "light" | "transparent";
}) {
  const [confirmState, setConfirmState] = useState<"initial" | "confirm">(
    "initial",
  );

  const handleClick = () => {
    if (confirmState === "initial") {
      setConfirmState("confirm");
      setTimeout(() => setConfirmState("initial"), 3000);
    } else {
      onDelete();
    }
  };

  if (variant === "transparent") {
    return (
      <Button
        miw={120}
        variant="transparent"
        fullWidth
        color={confirmState === "confirm" ? "red" : "gray"}
        onClick={handleClick}
        leftSection={
          confirmState === "confirm" ? (
            <IconCheck size={16} />
          ) : (
            <IconTrash size={16} />
          )
        }
      >
        <Text variant="label-md">
          {confirmState === "confirm" ? "Confirm" : "Delete"}
        </Text>
      </Button>
    );
  }

  return (
    <Button
      variant="light"
      color={confirmState === "confirm" ? "red" : "dimmed"}
      leftSection={
        confirmState === "confirm" ? (
          <IconCheck size={16} />
        ) : (
          <IconTrash size={16} />
        )
      }
      onClick={handleClick}
      radius="xs"
      size="xs"
    >
      <Text variant="label-md">
        {confirmState === "confirm" ? "Confirm" : "Delete"}
      </Text>
    </Button>
  );
}
