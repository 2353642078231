import { Text } from "@mantine/core";
import { upperFirst } from "@mantine/hooks";
import React from "react";
import { Horizontal } from "../mantine-layout-components/Horizontal";

export function InspoPickerFilterSection({
  icon,
  title,
  children,
}: {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Horizontal gap={"xs"} noWrap align="start">
      <Horizontal gap={"xxs"} noWrap mt={10} align="start">
        {/* @ts-ignore */}
        {React.cloneElement(icon, {
          size: "18px",
          color: "#949090",
        })}
        <Text variant="paragraph-sm" c={"#949090"} mt={"xxxs"}>
          {upperFirst(title)}:
        </Text>
      </Horizontal>

      {children}
    </Horizontal>
  );
}
