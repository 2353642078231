import { Group, type GroupProps } from "@mantine/core";
import React, { forwardRef } from "react";
import { commonProps, type CommonProps } from "../commonProps";

export type HorizontalProps = GroupProps & CommonProps;

export const Horizontal: React.FC<HorizontalProps> = forwardRef<
  any,
  HorizontalProps
>((props, ref: any) => {
  const {
    alignEnd,
    center,
    centerH,
    centerV,
    children,
    debug,
    fullH,
    fullW,
    noWrap,
    scrollable,
    spaceBetween,
    style,
    sxArray,
    h,
    w,
    ...rest
  } = props;

  return (
    <Group
      ref={ref}
      style={[
        // @ts-ignore
        {
          ...commonProps(props),
          ...(center && {
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }),
          // borderRadius: "var(--mantine-radius-lg)",
        },
        style,
      ]}
      {...{
        ...(centerV && {
          align: "center",
        }),
        ...(centerH && {
          justify: "center",
        }),
      }}
      {...rest}
      h={h}
      w={w}
    >
      {children}
    </Group>
  );
});
