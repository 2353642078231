import { useState } from "react";
import { Vertical } from "../mantine-layout-components/Vertical";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { ActionIcon, Text } from "@mantine/core";
import { IconArrowLeft, IconX } from "@tabler/icons-react";
import { InspoButton } from "../ui/InspoButton";
import { TextInput } from "@mantine/core";
import { InspoConfirmDeleteButton } from "../ui/InspoConfirmDeleteButton";
import type { SavedFolder } from "./ModalSaved";

export function EditFolderModalContent({
  folder,
  handleBack,
  handleClose,
  handleUpdateFolder,
  handleDeleteFolder,
}: {
  folder: SavedFolder;
  handleBack: () => void;
  handleClose: () => void;
  handleUpdateFolder: (folderData: { id: string; name: string }) => void;
  handleDeleteFolder: (id: string) => void;
}) {
  const [folderName, setFolderName] = useState(folder.name);

  const isValid = folderName?.length > 0;

  return (
    <Vertical fullW gap="md">
      <Horizontal fullW align="center" mb="md" style={{ position: "relative" }}>
        <ActionIcon
          variant="subtle"
          size={48}
          onClick={handleBack}
          style={{ position: "absolute", left: 0 }}
        >
          <IconArrowLeft size={24} />
        </ActionIcon>
        <Text variant="paragraph-lg" style={{ margin: "0 auto" }}>
          Edit folder
        </Text>
        <Horizontal gap="xs" style={{ position: "absolute", right: 0 }}>
          <InspoConfirmDeleteButton
            onDelete={() => handleDeleteFolder(folder.id)}
            variant="light"
          />
        </Horizontal>
      </Horizontal>

      <Horizontal
        noWrap
        bg={"white"}
        py={"xs"}
        px={"xs"}
        maw={"100%"}
        style={{
          borderRadius: "var(--mantine-radius-xs)",
        }}
        w={"100%"}
      >
        <TextInput
          variant="unstyled"
          placeholder="Folder name"
          ml={"xs"}
          mx={"auto"}
          bg={"white"}
          w={"100%"}
          autoFocus
          value={folderName}
          onChange={(event) => setFolderName(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (!isValid) {
              return;
            }
            if (event.key === "Enter") {
              handleUpdateFolder({ id: folder.id, name: folderName });
            }
          }}
          styles={{
            wrapper: {
              border: "0px",
            },
          }}
        />
      </Horizontal>

      <InspoButton
        theme="dark"
        fullWidth
        disabled={!isValid}
        onClick={() => {
          if (!isValid) {
            return;
          }
          handleUpdateFolder({ id: folder.id, name: folderName });
        }}
      >
        Save
      </InspoButton>
    </Vertical>
  );
}
