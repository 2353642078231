import { createContext, useContext } from "react";
import { industries, type IndustryType } from "../utils";
import { useLocalStorage } from "@mantine/hooks";

interface IndustryContextType {
  industry: IndustryType;
  setIndustry: (
    value: IndustryType | ((prevState: IndustryType) => IndustryType),
  ) => void;
}

const IndustryContext = createContext<IndustryContextType | undefined>(
  undefined,
);

export function IndustryProvider({ children }: { children: React.ReactNode }) {
  const [industry, setIndustry] = useLocalStorage<IndustryType>({
    key: "industry",
    defaultValue: industries[0],
  });

  return (
    <IndustryContext.Provider value={{ industry, setIndustry }}>
      {children}
    </IndustryContext.Provider>
  );
}

export function useIndustry() {
  const context = useContext(IndustryContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
