import { Anchor, Text } from "@mantine/core";

export function InspoButtonUnderline({
  onClick = () => {},
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <Anchor component="button" onClick={(e) => onClick?.()}>
      <Text variant={"paragraph-lg"}>{children}</Text>
    </Anchor>
  );
}
