import { Box, Text, type BoxProps } from "@mantine/core";
import type { CardColorScheme } from "src/utils/colorScheme/palette";

export function InspoCardTag({
  colorScheme,
  label,
}: { colorScheme: CardColorScheme; label: string } & BoxProps) {
  return (
    <Box
      key={label}
      py={"xxs"}
      px={"xs"}
      c={colorScheme.textdark}
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "var(--mantine-radius-xxxxl)",
      }}
    >
      <Text variant="card-label-md" c={colorScheme.textdark}>
        {label}
      </Text>
    </Box>
  );
}
