import { ActionIcon, Button, Menu, Text, UnstyledButton } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCheck,
  IconChevronDown,
  IconCirclePlus,
  IconPhotoDown,
  IconShare,
  IconShare3,
  IconX,
} from "@tabler/icons-react";
import html2canvas from "html2canvas";
import { useEffect, useMemo } from "react";
import { colorSchemes } from "src/utils/colorScheme/palette";
import { useCardsState } from "../contexts/CardsStateContext";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useFolders } from "../contexts/FoldersContext";
import { useFonts } from "../contexts/FontsContext";
import { useIndustry } from "../contexts/IndustryContext";
import { useUser } from "../contexts/UserContext";
import { useBentoCards } from "../hooks/useBentoCards";
import { industries, track } from "../utils";
import style from "./../../../styles/CustomComponents.module.scss";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { openModalLifetimeIfNeeded } from "./ModalLifetime";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { openModalUpdates } from "./ModalUpdates";
import {
  openModalSaved,
  updateMoodboards,
  type SavedFolder,
} from "./savedModal/ModalSaved";
import { InspoActionIcon } from "./ui/InspoActionIcon";

export function hasShareParams() {
  const searchParams = new URLSearchParams(document.location.search);
  const hasShareParams =
    searchParams.has("fonts") ||
    searchParams.has("palette") ||
    searchParams.has("industry") ||
    searchParams.has("titleIsLockedAt") ||
    searchParams.has("titleIsLockedAtFont") ||
    searchParams.has("titleIsLocal") ||
    searchParams.has("bodyIsLockedAt") ||
    searchParams.has("bodyIsLockedAtFont") ||
    searchParams.has("bodyIsLocal") ||
    searchParams.has("colorIsLockedAtSlug") ||
    searchParams.has("customColorScheme") ||
    searchParams.has("originColorScheme") ||
    searchParams.has("cardsState") ||
    searchParams.has("visibleCardsKeys");

  return hasShareParams;
}

export function hasOpenNewsletterModalParam() {
  const searchParams = new URLSearchParams(document.location.search);
  const hasOpenNewsletterModalParam = searchParams.get("newsletter");
  return (
    hasOpenNewsletterModalParam !== undefined &&
    hasOpenNewsletterModalParam !== null
  );
}

export const ShareSection = ({
  version = "desktop",
}: {
  version: "desktop" | "mobile";
}) => {
  const { user, isPro } = useUser();
  const { folders, refetchFolders } = useFolders();
  const { industry } = useIndustry();
  const { title, body } = useFonts();
  const { currentColorScheme: colorScheme } = useColorPalette();
  const shareUrl = useShareUrl();

  if (version === "mobile") {
    return <ShareSectionMobile onClick={() => {}} />;
  }

  const addedInFolders = folders
    .filter((folder) =>
      folder.moodboards.find((moodboard) => moodboard === shareUrl),
    )
    .map((folder) => ({
      id: folder.id,
    }));

  async function handleDownloadImage() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "save_moodboard_img",
      })
    ) {
      return;
    }

    if (
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: "save_moodboard_img",
      })
    ) {
      return;
    }

    const bentoElement = document.getElementById("bento-container");
    if (!bentoElement) return;

    const canvas = await html2canvas(bentoElement, {
      scale: 2,
      useCORS: true,
      backgroundColor: null,
    });

    const filename = `inspotype-industry-${industry["label"]}-title-${title.font["readable-font-family"]}-body-${body.font["readable-font-family"]}-color-${colorScheme["name"]}.png`;

    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = filename;
    link.click();

    notifications.show({
      title: "Downloaded moodboard image",
      message: "Check your downloads folder",
      color: "black",
      icon: <IconCheck size={14} />,
    });
  }

  async function handleAddToNewFolder() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "add_to_folder",
      })
    ) {
      return;
    }

    const totalMoodboards = folders.reduce(
      (acc, folder) => acc + folder.moodboards.length,
      0,
    );

    if (!isPro && totalMoodboards >= 3) {
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: "add_to_new_folder",
      });
      return;
    }

    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "create_new_folder",
      })
    ) {
      return;
    }

    openModalSaved({
      step: "createNewFolder",
      shareUrl: shareUrl,
    });
  }

  async function handleToggleInFolder(
    folder: SavedFolder,
    currentMoodboardUrl: string,
  ) {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "add_to_folder",
      })
    ) {
      return;
    }

    const isInFolder = folder.moodboards.includes(currentMoodboardUrl);

    const totalMoodboards = folders.reduce(
      (acc, folder) => acc + folder.moodboards.length,
      0,
    );

    if (!isPro && totalMoodboards >= 3 && !isInFolder) {
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: "add_to_folder",
      });
      return;
    }

    if (isInFolder) {
      await updateMoodboards(
        folder.id,
        folder.moodboards.filter((m) => m !== currentMoodboardUrl),
      );
    } else {
      // Add the new moodboard to the beginning of the array
      await updateMoodboards(folder.id, [
        currentMoodboardUrl,
        ...folder.moodboards,
      ]);
    }

    await refetchFolders();
  }

  return (
    <Horizontal gap={"xxs"}>
      <Menu
        shadow="md"
        width={200}
        classNames={{
          item: style.addToFolderMenuItem,
        }}
        styles={{
          dropdown: {
            backgroundColor: "var(--mantine-color-gray-9)",
            borderColor: "var(--mantine-color-gray-6)",
            borderWidth: "2px",
            zIndex: 100,
          },
        }}
      >
        <Menu.Target>
          <Button
            bg={"black"}
            c={"white"}
            px={"sm"}
            py={"md"}
            h={44}
            radius={"xs"}
            styles={{
              label: {
                height: "44px",
              },
            }}
          >
            <Horizontal gap={"0"}>
              {addedInFolders.length > 0 && <IconBookmarkFilled size={24} />}
              {addedInFolders.length === 0 && <IconBookmark size={24} />}
              <Text ml={"xs"} variant="paragraph-md">
                Add to folder
              </Text>

              {addedInFolders.length > 0 && (
                <Text
                  ml={"xs"}
                  variant="label-sm"
                  bg={"gray.6"}
                  p={"xxs"}
                  style={{
                    borderRadius: "var(--mantine-radius-xxs)",
                  }}
                >
                  {addedInFolders.length}
                </Text>
              )}

              <IconChevronDown
                size={20}
                style={{ marginLeft: "var(--mantine-spacing-sm)" }}
              />
            </Horizontal>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {!folders.length && (
            <Horizontal gap={"xs"} p={"lg"} center>
              <Text variant="paragraph-sm" c={"gray.4"}>
                No folders yet
              </Text>
            </Horizontal>
          )}

          {folders.length > 0 && (
            <>
              <Menu.Label>Folders</Menu.Label>
              {folders.map((folder) => {
                const isInFolder = addedInFolders.some(
                  (f) => f.id === folder.id,
                );
                return (
                  <Menu.Item
                    key={folder.id}
                    onClick={() => {
                      handleToggleInFolder(folder, shareUrl);
                    }}
                  >
                    <Horizontal gap={"xs"} h={"20px"} align="center">
                      {isInFolder && (
                        <IconCheck
                          color="white"
                          size={16}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                      )}
                      <Text
                        variant="paragraph-sm"
                        c={isInFolder ? "white" : "gray.4"}
                        flex={1}
                        style={{ marginLeft: isInFolder ? 0 : 20 }}
                      >
                        {folder.name}
                      </Text>
                      <Text variant="paragraph-sm" c={"gray.4"}>
                        {folder.moodboards.length}
                      </Text>
                      {isInFolder && (
                        <ActionIcon
                          mr={-4}
                          className={style.removeIcon}
                          size={18}
                          radius={"xxs"}
                          variant="transparent"
                        >
                          <IconX size={18} color="gray" />
                        </ActionIcon>
                      )}
                    </Horizontal>
                  </Menu.Item>
                );
              })}
            </>
          )}

          <UnstyledButton onClick={handleAddToNewFolder} w={"100%"}>
            <Horizontal
              gap={"xs"}
              px={"sm"}
              py={"sm"}
              bg={"gray.8"}
              style={{
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <IconCirclePlus size={18} color="gray" />
              <Text variant="paragraph-sm" c={"gray.4"}>
                Add to new folder
              </Text>
            </Horizontal>
          </UnstyledButton>
        </Menu.Dropdown>
      </Menu>

      <ShareButton />

      <InspoActionIcon
        theme="dark"
        icon={<IconPhotoDown size={24} />}
        onClick={handleDownloadImage}
      />
    </Horizontal>
  );
};

function ShareSectionMobile({ onClick }: { onClick: () => void }) {
  return (
    <InspoActionIcon
      icon={<IconShare3 />}
      theme="light"
      radius={"xl"}
      onClick={onClick}
    />
  );
}

export function ShareButton() {
  const { user } = useUser();
  const { copy } = useClipboard();
  const { setIndustry } = useIndustry();
  const { setCardsState, setVisibleCardsKeys } = useCardsState();
  const { title, body, fontPairs, setFontPairIndex } = useFonts();
  const {
    customColorSchemeIsActive,
    setColorSchemeIndex,
    setOriginColorScheme,
    setCustomColorScheme,
    setCustomColorSchemeIsActive,
    setColorIsLockedAtSlug,
  } = useColorPalette();

  const shareUrl = useShareUrl();

  // const ogCardParams = new URLSearchParams();
  // ogCardParams.append("fonts", fontsParam ?? "");
  // ogCardParams.append("palette", paletteParam ?? "");

  // const url = `${window.location.origin}/api/s/og?${ogCardParams.toString()}`;
  // const url = `${window.location.origin}/assets/card.png`;

  useEffect(function onlyForTracking() {
    if (hasShareParams()) {
      track({
        event: "share",
        properties: {
          action: "open",
          url: searchParams.toString(),
        },
      });
      return;
    }

    if (hasOpenNewsletterModalParam()) {
      track({
        event: "open_newsletter_modal",
        properties: {
          properties: { source: "direct_link" },
        },
      });
      openModalUpdates({ isMobile: false });
      removeUrlParam("newsletter");
    }
  }, []);

  // read query params
  // https://nextjs.org/docs/app/api-reference/functions/use-search-params#returns
  let searchParams = new URLSearchParams(document.location.search);
  const hasFontsParam = searchParams.has("fonts");
  const fontsParam = searchParams.get("fonts");
  const hasPaletteParam = searchParams.has("palette");
  const paletteParam = searchParams.get("palette");
  const hasIndustryParam = searchParams.has("industry");
  const industryParam = searchParams.get("industry");
  const hasCardsStateParam = searchParams.has("cardsState");
  const cardsStateParam = searchParams.get("cardsState");
  const hasVisibleCardsKeysParam = searchParams.has("visibleCardsKeys");
  const visibleCardsKeysParam = searchParams.get("visibleCardsKeys");

  const hasTitleIsLocalParam = searchParams.has("titleIsLocal");
  const titleIsLocalParam = searchParams.get("titleIsLocal");
  const hasTitleIsLockedAtParam = searchParams.has("titleIsLockedAt");
  const titleIsLockedAtParam = searchParams.get("titleIsLockedAt");
  const hasTitleIsLockedAtFontParam = searchParams.has("titleIsLockedAtFont");
  const titleIsLockedAtFontParam = searchParams.get("titleIsLockedAtFont");
  const hasBodyIsLocalParam = searchParams.has("bodyIsLocal");
  const bodyIsLocalParam = searchParams.get("bodyIsLocal");
  const hasBodyIsLockedAtParam = searchParams.has("bodyIsLockedAt");
  const bodyIsLockedAtParam = searchParams.get("bodyIsLockedAt");
  const hasBodyIsLockedAtFontParam = searchParams.has("bodyIsLockedAtFont");
  const bodyIsLockedAtFontParam = searchParams.get("bodyIsLockedAtFont");
  const hasColorIsLockedAtSlugParam = searchParams.has("colorIsLockedAtSlug");
  const colorIsLockedAtSlugParam = searchParams.get("colorIsLockedAtSlug");
  const hasCustomColorSchemeParam = searchParams.has("customColorScheme");
  const customColorSchemeParam = searchParams.get("customColorScheme");
  const hasOriginColorSchemeParam = searchParams.has("originColorScheme");
  const originColorSchemeParam = searchParams.get("originColorScheme");

  function removeUrlParam(param: string) {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, "", url.toString());
  }

  useEffect(() => {
    if (!hasFontsParam || !fontsParam) return;
    const fontPair = fontPairs.find((f) => f.slug === fontsParam);
    if (fontPair !== undefined) {
      const fontPairIndex = fontPairs.indexOf(fontPair);
      setFontPairIndex(fontPairIndex);
    }
    removeUrlParam("fonts");
  }, [fontsParam, hasFontsParam]);

  useEffect(() => {
    if (!hasPaletteParam || !paletteParam) return;
    const colorScheme = colorSchemes.find((c) => c.slug === paletteParam);
    if (colorScheme !== undefined) {
      const colorSchemeIndex = colorSchemes.indexOf(colorScheme);
      setColorSchemeIndex(colorSchemeIndex);
      setCustomColorSchemeIsActive(false);
    }
    removeUrlParam("palette");
  }, [paletteParam, hasPaletteParam]);

  useEffect(() => {
    if (!hasIndustryParam || !industryParam) return;
    const industry = industries.find((i) => i.slug === industryParam);
    if (industry !== undefined) {
      setIndustry(industry);
    }
    removeUrlParam("industry");
  }, [industryParam, hasIndustryParam, industries]);

  useEffect(() => {
    if (
      !hasTitleIsLockedAtParam ||
      titleIsLockedAtParam === null ||
      title.isLocal
    ) {
      return;
    }
    const titleIsLockedAt = parseInt(titleIsLockedAtParam);
    if (titleIsLockedAt >= 0 && titleIsLockedAt < fontPairs.length) {
      title.setIsLockedAt(titleIsLockedAt);
    }
    removeUrlParam("titleIsLockedAt");

    if (!hasTitleIsLockedAtFontParam || !titleIsLockedAtFontParam) return;
    title.setIsLockedAtFont(titleIsLockedAtFontParam);
    removeUrlParam("titleIsLockedAtFont");

    if (!hasTitleIsLocalParam || !titleIsLocalParam) return;
    title.setIsLocal(titleIsLocalParam === "true");
    removeUrlParam("titleIsLocal");
  }, [
    titleIsLockedAtParam,
    hasTitleIsLockedAtParam,
    hasTitleIsLockedAtFontParam,
    titleIsLockedAtFontParam,
    hasTitleIsLocalParam,
    titleIsLocalParam,
    title.isLocal,
  ]);

  useEffect(() => {
    if (
      !hasBodyIsLockedAtParam ||
      bodyIsLockedAtParam === null ||
      body.isLocal
    ) {
      return;
    }
    const bodyIsLockedAt = parseInt(bodyIsLockedAtParam);
    if (bodyIsLockedAt >= 0 && bodyIsLockedAt < fontPairs.length) {
      body.setIsLockedAt(bodyIsLockedAt);
    }
    removeUrlParam("bodyIsLockedAt");

    if (!hasBodyIsLockedAtFontParam || !bodyIsLockedAtFontParam) return;
    body.setIsLockedAtFont(bodyIsLockedAtFontParam);
    removeUrlParam("bodyIsLockedAtFont");

    if (!hasBodyIsLocalParam || !bodyIsLocalParam) return;
    body.setIsLocal(bodyIsLocalParam === "true");
    removeUrlParam("bodyIsLocal");
  }, [
    bodyIsLockedAtFontParam,
    bodyIsLockedAtParam,
    hasBodyIsLockedAtFontParam,
    hasBodyIsLockedAtParam,
    hasBodyIsLocalParam,
    bodyIsLocalParam,
    body.isLocal,
  ]);

  useEffect(() => {
    if (
      !hasColorIsLockedAtSlugParam ||
      colorIsLockedAtSlugParam === null ||
      customColorSchemeIsActive
    ) {
      return;
    }
    const colorIsLockedAtSlug = colorSchemes.find(
      (c) => c.slug === colorIsLockedAtSlugParam,
    )?.slug;
    setColorIsLockedAtSlug(colorIsLockedAtSlug);
    removeUrlParam("colorIsLockedAtSlug");
  }, [colorIsLockedAtSlugParam, hasColorIsLockedAtSlugParam]);

  useEffect(() => {
    if (!hasCustomColorSchemeParam || !hasOriginColorSchemeParam) {
      return;
    }

    try {
      customColorSchemeParam &&
        setCustomColorScheme(JSON.parse(customColorSchemeParam));
      originColorSchemeParam &&
        setOriginColorScheme(JSON.parse(originColorSchemeParam));

      setCustomColorSchemeIsActive(true);
    } catch (e) {}

    removeUrlParam("colorIsLockedAtSlug");
    removeUrlParam("customColorScheme");
    removeUrlParam("originColorScheme");
  }, []);

  useEffect(() => {
    if (!hasCardsStateParam || !cardsStateParam) return;
    setCardsState(JSON.parse(cardsStateParam));
    removeUrlParam("cardsState");
  }, [cardsStateParam, hasCardsStateParam]);

  useEffect(() => {
    if (!hasVisibleCardsKeysParam || !visibleCardsKeysParam) return;
    setVisibleCardsKeys(JSON.parse(visibleCardsKeysParam));
    removeUrlParam("visibleCardsKeys");
  }, [visibleCardsKeysParam, hasVisibleCardsKeysParam]);

  function onClickShare() {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "share",
      })
    ) {
      return;
    }

    if (!shareUrl) return;

    copy(shareUrl);

    notifications.show({
      id: "share",
      title: "Copied moodboard link to clipboard",
      message: (() => {
        if (title.isLocal && body.isLocal) {
          return "The title and body are locked to local fonts and will not be shared in your URL";
        } else if (title.isLocal) {
          return "The title is locked to a local font and will not be shared in your URL";
        } else if (body.isLocal) {
          return "The body font is locked to a local font and will not be shared in your URL";
        }
      })(),
      color: "dark",
      icon: <IconCheck size={14} />,
    });

    track({
      event: "share",
      properties: {
        action: "create",
        url: new URL(shareUrl).searchParams.toString(),
      },
    });
  }

  return (
    <InspoActionIcon
      theme="dark"
      icon={<IconShare size={24} />}
      onClick={onClickShare}
    />
  );
}

function useShareUrl() {
  const { user } = useUser();
  const { industry } = useIndustry();
  const {
    currentColorScheme,
    customColorScheme,
    originColorScheme,
    customColorSchemeIsActive,
    colorIsLockedAtSlug,
  } = useColorPalette();
  const { currentFontPair, title, body } = useFonts();
  const bentoCards = useBentoCards();
  const { cardsState, visibleCardsKeys } = useCardsState();

  const shareUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.append("fonts", currentFontPair.slug);
    params.append("palette", currentColorScheme.slug);
    params.append("industry", industry.slug);

    title.isLockedAt !== undefined &&
      params.append("titleIsLockedAt", title.isLockedAt.toString());
    title.isLockedAtFont !== undefined &&
      params.append("titleIsLockedAtFont", title.isLockedAtFont.toString());
    title.isLocal && params.append("titleIsLocal", "true");

    body.isLockedAt !== undefined &&
      params.append("bodyIsLockedAt", body.isLockedAt.toString());
    body.isLockedAtFont !== undefined &&
      params.append("bodyIsLockedAtFont", body.isLockedAtFont.toString());
    body.isLocal && params.append("bodyIsLocal", "true");

    colorIsLockedAtSlug !== undefined &&
      params.append("colorIsLockedAtSlug", colorIsLockedAtSlug);

    if (customColorSchemeIsActive && customColorScheme && originColorScheme) {
      params.append("customColorScheme", JSON.stringify(customColorScheme));
      params.append("originColorScheme", JSON.stringify(originColorScheme));
    }

    const industryCardsState = (bentoCards || [])
      .map((card) => card.props.children.key)
      .filter((key) => key !== undefined)
      .reduce((acc, key) => {
        if (key in cardsState) {
          acc[key] = cardsState[key];
        }
        return acc;
      }, {});
    params.append("cardsState", JSON.stringify(industryCardsState));
    params.append("visibleCardsKeys", JSON.stringify(visibleCardsKeys));

    const urlToShare = `${window.location.origin}?${params.toString()}`;

    return urlToShare;
  }, [
    user,
    industry,
    currentColorScheme,
    customColorScheme,
    originColorScheme,
    cardsState,
    colorIsLockedAtSlug,
    customColorSchemeIsActive,
    visibleCardsKeys,
  ]);

  return shareUrl;
}

