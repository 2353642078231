import { Text, Tooltip, type TooltipProps } from "@mantine/core";
import { Horizontal } from "../mantine-layout-components/Horizontal";

export function InspoKeyboardShortcutTooltip({
  label,
  shortcut,
  children,
  ...props
}: {
  label: string;
  shortcut: string | string[] | undefined;
  children: React.ReactNode;
} & TooltipProps) {
  return (
    <Tooltip
      bg={"gray.9"}
      h={30}
      style={{
        border: "1px solid rgba(68, 64, 60, 1)",
        borderRadius: "var(--mantine-radius-xs)",
      }}
      label={
        <Horizontal gap={"xs"} center fullH>
          <Text variant="paragraph-sm">{label}</Text>
          {!!shortcut && (
            <Horizontal gap={"xxs"}>
              {(Array.isArray(shortcut) ? shortcut : [shortcut]).map(
                (shortcut, i) => (
                  <Text
                    key={i}
                    variant="label-md"
                    p={"xxs"}
                    bg={"gray.8"}
                    style={{
                      border: "1px solid rgba(68, 64, 60, 1)",
                      borderRadius: "var(--mantine-radius-xxs)",
                    }}
                  >
                    {shortcut}
                  </Text>
                ),
              )}
            </Horizontal>
          )}
        </Horizontal>
      }
      position="top"
      withArrow={false}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
