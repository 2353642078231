import { Button, Text, type ButtonProps } from "@mantine/core";
import React from "react";

const buttonTheme = {
  dark: {
    bg: "black",
    color: "white",
  },
  accent: {
    bg: "purple",
    color: "white",
  },
  light: {
    bg: "white",
    color: "black",
  },
};

type ButtonThemeType = keyof typeof buttonTheme;

export function InspoButton({
  theme = "accent",
  icon = undefined,
  iconPos = undefined,
  onClick = () => {},
  children,
  ...props
}: {
  theme: ButtonThemeType;
  icon?: React.ReactNode;
  iconPos?: "left" | "right" | undefined;
  onClick?: (e) => void;
  children: React.ReactNode;
} & ButtonProps) {
  return (
    <Button
      onClick={(e) => onClick?.(e)}
      px={"md"}
      py={"md"}
      h={44}
      radius={"xs"}
      c={buttonTheme[theme].color}
      bg={buttonTheme[theme].bg}
      opacity={props.disabled ? 0.5 : 1}
      styles={{
        label: {
          height: "44px",
        },
      }}
      leftSection={
        icon &&
        iconPos === "left" &&
        // @ts-ignore
        React.cloneElement(icon, { size: 16 })
      }
      rightSection={
        icon &&
        iconPos === "right" &&
        // @ts-ignore
        React.cloneElement(icon, { size: 16 })
      }
      {...props}
    >
      <Text variant={"paragraph-md"} style={{ cursor: "pointer" }}>
        {children}
      </Text>
    </Button>
  );
}
