import { Divider } from "@mantine/core";

export function InspoToolbarDivider() {
  return (
    <Divider
      ml={"xs"}
      mr={"xxs"}
      h={58}
      w={2}
      bg={"#B5B5B5"}
      style={{ zIndex: "1000", borderTop: "0" }}
    />
  );
}
