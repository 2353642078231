import { fontPairs } from "src/utils/typography/configs/fontPairs";

export const PreloadAllFonts = ({}) => {
  const flatFonts = Array.from(
    fontPairs.reduce((acc, curr) => {
      acc.add(curr.title["font-family"]);
      acc.add(curr.body["font-family"]);
      return acc;
    }, new Set()),
  );

  return (
    <div
      id="font-preload"
      style={{
        visibility: "hidden",
        position: "absolute",
        top: "-9999px",
        left: "-9999px",
      }}
    >
      {flatFonts.map((f, i) => {
        return <span key={i} style={{ fontFamily: f as string }}></span>;
      })}
    </div>
  );
};
