import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://29a62085b65068880ecbf7046167c69f@o4506881043857408.ingest.us.sentry.io/4506881044971520",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});