import { Box, Divider, Image, Text } from "@mantine/core";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import typo from "./../../../../styles/Typography.module.scss";

export function InspoCreatedBy() {
  return (
    <>
      <Divider
        w={"100%"}
        color=""
        label="CREATED BY"
        labelPosition="center"
        classNames={{ label: typo.labelLg }}
      />

      <Horizontal justify="space-evenly" fullW mb={"md"}>
        {[
          {
            account: "https://twitter.com/fabsoldano",
            imageUrl:
              "https://pbs.twimg.com/profile_images/961940975890391041/gA0FuT4f_400x400.jpg",
            name: "Fabrizio Soldano",
            handle: "@fabsoldano",
          },
          {
            account: "https://twitter.com/albigiu",
            imageUrl:
              "https://pbs.twimg.com/profile_images/1469591607561310208/XMUTbOEW_400x400.jpg",
            name: "Alberto Giunta",
            handle: "@albigiu",
          },
        ].map((user) => (
          <Box
            key={user.name}
            component="a"
            href={user.account}
            target="_blank"
            rel="noopener noreferrer"
            td={"none"}
          >
            <Vertical gap={"xxxs"} center>
              <Image src={user.imageUrl} w={40} h={40} radius={"100%"} />
              <Text variant="paragraph-lg" c={"#1C1B1F"}>
                {user.name}
              </Text>
              <Text variant="paragraph-md" opacity={0.6} c={"#1C1B1F"}>
                {user.handle}
              </Text>
            </Vertical>
          </Box>
        ))}
      </Horizontal>
    </>
  );
}
