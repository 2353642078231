import { Badge, Text, Title } from "@mantine/core";
import {
  customizeTextVariantIfNeededForCardPreview,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";

type TextLinesWithUpdatesTagContentProps = {
  titles: string[];
  badges: string[];
};

export const textLinesWithUpdatesTagContent = {
  list1: {
    titles: [
      "New Arrivals",
      "Collections",
      "Size Guide",
      "Sustainability",
      "Look book",
    ],
    badges: ["45"],
  },
} as const satisfies Record<string, TextLinesWithUpdatesTagContentProps>;

export default function TextLinesWithUpdatesTag({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<TextLinesWithUpdatesTagContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      center
      px="md"
      gap={"xs"}
      bg={colorScheme.background}
      {...props}
    >
      {content.titles.map((title, i) => (
        <Horizontal key={i} fullW gap={"xs"} noWrap>
          <Title
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-headline-sm",
              titleFontFamily,
            )}
            ff={titleFontFamily}
            c={colorScheme.textdark}
            ta={"start"}
          >
            {title}
          </Title>
          {content.badges[i] && (
            <Badge bg={colorScheme.ctadark} c={colorScheme.textlight}>
              {content.badges[i]}
            </Badge>
          )}
        </Horizontal>
      ))}
    </Vertical>
  );
}
