import { AppShell, Box, Divider } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React from "react";
import { type BentoCardChildrenProps } from "../../utils/colorScheme/palette";
import { Mobile } from "./Mobile";
import { AppHeader } from "./components/AppHeader";
import { ColorSchemeSection } from "./components/ColorSchemeSection";
import { FontPairingSection } from "./components/FontPairingSection";
import { HistorySection } from "./components/HistorySection";
import { IndustrySection } from "./components/IndustrySection";
import { PreloadAllFonts } from "./components/PreloadAllFonts";
import { RandomSection } from "./components/RandomSection";
import { ShareSection } from "./components/ShareSection";
import { BentoContainer } from "./components/bentoCards/BentoCards";
import { Horizontal } from "./components/mantine-layout-components/Horizontal";
import { Vertical } from "./components/mantine-layout-components/Vertical";
import { useCardsState } from "./contexts/CardsStateContext";
import { useBentoCards } from "./hooks/useBentoCards";
import { useIsMobile } from "./hooks/useIsMobile";

export default function Home({}) {
  const { cardsState, setCardState } = useCardsState();
  const bentoCards = useBentoCards();

  const isMobile = useIsMobile();
  const { width } = useViewportSize();

  if (!width) {
    return <></>;
  }

  return (
    <main
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "var(--mantine-breakpoint-lg)",
        margin: "auto",
      }}
    >
      <PreloadAllFonts />
      <AppShell
        header={{ height: isMobile ? 60 : 172 }}
        bg={isMobile ? "rgba(41, 37, 36, 1)" : "#F5F5F5"}
        style={{
          maxWidth: "var(--mantine-breakpoint-lg)",
          margin: "auto",
        }}
      >
        <AppShell.Header
          bg={isMobile ? "rgba(41, 37, 36, 1)" : "#F5F5F5"}
          px={isMobile ? "sm" : "0"}
          withBorder={false}
          style={{
            margin: "auto",
          }}
        >
          <Vertical gap={0}>
            <Box w={"100%"} px={"lg"}>
              <AppHeader />
            </Box>

            {!isMobile && (
              <>
                <InspoDoubleDivider />
                <Horizontal
                  fullW
                  spaceBetween
                  h={72}
                  m={"auto"}
                  noWrap
                  px={"xxl"}
                  mt={"sm"}
                  style={{
                    maxWidth: "var(--mantine-breakpoint-lg)",
                  }}
                >
                  <Horizontal gap={"xl"} noWrap>
                    <IndustrySection />
                    <HistorySection />
                  </Horizontal>
                  <Horizontal gap={"xxs"}>
                    <ShareSection version="desktop" />
                  </Horizontal>
                </Horizontal>
              </>
            )}
          </Vertical>
        </AppShell.Header>

        <AppShell.Main
          pb={"100px"}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isMobile && <Mobile />}
          {!isMobile && (
            <>
              <Vertical
                m={"auto"}
                w={"100%"}
                maw={"var(--mantine-breakpoint-lg)"}
                px={"xxl"}
              >
                <BentoContainer
                  style={{
                    height: "min(calc(100vh - 300px), 750px)",
                    paddingRight: "calc(var(--mantine-spacing-xs) * 7)",
                    paddingBottom: "calc(var(--mantine-spacing-xs) * 3)",
                  }}
                >
                  {bentoCards.map((card) => {
                    return React.cloneElement(card, {
                      key: `card-${card.props.children.key}`,
                      children: React.cloneElement(card.props.children, {
                        cardState: cardsState[card.props.children.key],
                        setCardState: (state: BentoCardChildrenProps) =>
                          setCardState(card.props.children.key, state),
                      }),
                    });
                  })}
                </BentoContainer>

                <Horizontal
                  style={{
                    position: "fixed",
                    width: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "35px",
                    zIndex: 2,
                  }}
                  noWrap
                  fullW
                  center
                  gap={"sm"}
                >
                  <FontPairingSection />
                  <ColorSchemeSection />
                  <RandomSection version="desktop" />
                </Horizontal>
              </Vertical>
            </>
          )}
        </AppShell.Main>
      </AppShell>
    </main>
  );
}

function InspoDoubleDivider() {
  return (
    <>
      <Divider w={"100%"} color="#E3E3E3" />
      <Divider w={"100%"} color="white" />
    </>
  );
}
