import { Badge, Box, Image, Space, Text, Title } from "@mantine/core";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";

type BlogArticleWithImageTopContentProps = {
  title: string;
  description: string;
  button: string;
  imgs: string[];
};

export const blogArticleWithImageTopContent = {
  blog1: {
    title: "Samba Sweetness",
    description: "Succulent and bold, a dessert that captures bite.",
    button: "Discover",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
  },
  blog2: {
    title: "Breaking Barriers",
    description:
      "We exist to bring inspiration and innovation to every athlete",
    button: "Explore",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
  },
  blog3: {
    title: "Beauty",
    description: "It's something about beauty",
    button: "Explore",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
  },
  blog4: {
    title: "Sustainable Chic",
    description: "Eco-friendly fashion that doesn't compromise on style.",
    button: "Explore",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
  },
} as const satisfies Record<string, BlogArticleWithImageTopContentProps>;

export default function BlogArticleWithImageTop({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BlogArticleWithImageTopContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Vertical fullW fullH bg={colorScheme.background} gap={0} justify="space-between" {...props}>
      
        <Box h={"40%"} w={"100%"}>
          <Image
            src={uploadedImagePath ?? content.imgs[imgIndex]}
            fit="cover"
            w={"100%"}
            h={"100%"}
          />
        </Box>


        <Vertical mx="md" gap={"xs"}>
          <Title
            variant="card-headline-xs"
            ta={"left"}
            c={colorScheme.textdark}
          >
            {content.title}
          </Title>


          <Text variant="card-paragraph-lg" c={colorScheme.textdark}>
            {content.description}
          </Text>
        </Vertical>
      

      <Text
        bg={colorScheme.ctadark}
        c={colorScheme.textlight}
        py={"xs"}
        px={"md"}
        mx={"md"}
        mb={"md"}
        variant="card-paragraph-lg"
        style={{ borderRadius: "var(--mantine-radius-xs)" }}
      >
        {content.button}
      </Text>
    </Vertical>
  );
}