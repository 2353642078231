import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";

import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Image } from "@mantine/core";

type BgPhotoContentProps = {
  imgs: string[];
  alt: string;
};

export const bgPhotoContent = {
  editorialLandscape: {
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "editorial",
  },
  editorialSquare: {
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
    alt: "editorial",
  },
  fashionLandscape: {
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "fashion",
  },
  fashionSquare: {
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
    alt: "fashion",
  },
  financeLandscape: {
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "finance",
  },
  financeSquare: {
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_square",
    }),
    alt: "finance",
  },
  foodLandscape: {
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "food",
  },
  foodSquare: {
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_square",
    }),
    alt: "food",
  },
  sportLandscape: {
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "sport",
  },
  sportSquare: {
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_square",
    }),
    alt: "sport",
  },
  beautyLandscape: {
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "beauty",
  },
  beautySquare: {
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_square",
    }),
    alt: "beauty",
  },
} as const satisfies Record<string, BgPhotoContentProps>;

export default function BgPhoto({
  colorScheme,
  content,
  imgIndex,
  // @ts-ignore
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<BgPhotoContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Image
      loading="eager"
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      fetchPriority="high"
      {...props}
    />
  );
}
