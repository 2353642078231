import { Chip, type ChipProps } from "@mantine/core";
import style from "./../../../../styles/CustomComponents.module.scss";
import typo from "./../../../../styles/Typography.module.scss";

export function InspoChip({
  children,
  ...props
}: { children: React.ReactNode } & ChipProps) {
  return (
    <Chip
      classNames={{
        label: [typo.labelSm, style.inspoChipLabel].join(" "),
      }}
      styles={{
        root: {
          cursor: "pointer",
          height: 30,
        },
        iconWrapper: { display: "none" },
      }}
      {...props}
    >
      {children}
    </Chip>
  );
}
