import {
  Badge,
  Button,
  Select,
  Text,
  Title,
  colorsTuple,
  createTheme,
  type MantineThemeOverride,
} from "@mantine/core";
import typo from "./Typography.module.scss";
import typoCards from "./TypographyCards.module.scss";

export const theme: MantineThemeOverride = createTheme({
  primaryColor: "dark",
  spacing: {
    xxxs: "2px",
    xxs: "4px",
    xs: "8px",
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "48px",
    xxl: "64px",
    xxxl: "96px",
    xxxxl: "192px",
  },
  radius: {
    xxs: "4px",
    xs: "8px",
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "48px",
    xxl: "64px",
    xxxl: "96px",
    xxxxl: "192px",
  },
  shadows: {
    md: "blur(20px) drop-shadow(0 8px 0 rgba(var(--mantine-black-rgb), 0.2))",
  },
  black: "#000",
  white: "#fff",
  colors: {
    purple: colorsTuple("#6731FF"),
    lime300: colorsTuple("#DEFF59"),
    lime700: colorsTuple("#648605"),
    sky200: colorsTuple("#B6F1FA"),
    sky700: colorsTuple("#126D8C"),
    gray: [
      "#f8f9fa", // 50
      "#F5F5F4", // 100
      "#E7E5E4", // 200
      "#D6D3D1", // 300
      "#A8A29E", // 400
      "#78716C", // 500
      "#57534E", // 600
      "#44403C", // 700
      "#292524", // 800
      "#1C1917", // 900
      "#0C0A09", // 950
    ],
  },
  components: {
    Title: Title.extend({
      classNames: { root: typo.titleRoot + " " + typoCards.cardTitleRoot },
    }),
    Text: Text.extend({
      classNames: { root: typo.textRoot + " " + typoCards.cardTextRoot },
    }),
    Badge: Badge.extend({ classNames: { root: typo.badgeLabel } }),
    Button: Button.extend({ classNames: { label: typo.buttonLabel } }),
    Select: Select.extend({
      classNames: {
        input: typo.paragraphMd,
        option: typo.paragraphMd,
      },
    }),
  },
  fontSizes: {
    xs: "12px",
    sm: "16px",
    md: "20px",
    lg: "24px",
    xl: "36px",
    xxl: "42px",
    xxxl: "50px",
  },
});
