export const images = {
  editorial: [
    "mhsv3mecr6wydpu42a1r",
    "rmvrgnfznecefjm0tyx0",
    "vrztqubkvep1r7wbaei2",
    "beqik6mrbealmhdkiqsr",
    "z75ycfmpijjjapt4xqlt",
    "i85imaowpzeudmxplzrv",
    "hikurmfynssaeinswtcd",
    "vubds13jjcwz9w09e3im",
    "pqwwg8owuczw1gvfbzpk",
    "o59iuio3zolz19f9hotj",
    "abixplyb7fwigpptvwgy",
    "pvfxwmx2xq3ayj2r59lm",
    "tpvtznintgrglj6mzpxr",
    "soz9ciuni95wgkg3whmq",
    "w7olizi9sw7cqwulal6a",
    "i6cl6mhtoplhdw08sbgb",
    "lvifgobxzhr2seojjt0p",
    "b2d5uaxtinkth3wky1to",
    "uecpo7ftv7hjqgskhebp",
    "uc6hue0bw7eo0didjyth",
    "u8tnwdbhcrtuzjj1w7hl",
    "u94sznnhypv6fmgwbtfg",
    "ij1dh7tvks3q8tzns1ii",
    "gjohwmiubwlu5n9ecklr",
    "slyzi1zuljgg9squnehw",
    "igqokyzcnvljbobcpzll",
    "xxwn6c5qtyekuff2zxdv",
    "ylhuvu5gougqvsirwbjt",
    "bnobivhp6tnmgnuanzqn",
    "x2cqkdgnp3yafkhovore",
    "fsfniozg5h91qedohbxg",
    "reh2n400gfkrgtwkfgjt",
    "fffbn9ze4dgzbgbeoufn",
    "qijwlcle9m4pnddr97ux",
    "yncyoahxyivsw8us26jy",
    "dp56j6dofdxksplbtpbx",
    "ufvfxbfsykgpkrrjys6h",
    "xfrxrql1sx1q0hbzgvln",
    "tc4unwikilatqsulwwmg",
    "tnsxdukdzirqebwbw1ax",
    "uvaolarjjiblo03gdczz",
    "g5tikscxhyj8jlq9luxx",
    "ns0qtfky7bhwcvcz87gn",
    "rfsdt16lqhj7ue6waee7",
    "dyfxlushfk1hnwyt5jys",
    "v8toziizxgvmixpakk0z",
    "xongfdpmtcm0fcjxvcx0",
    "bganeweplbebxgia3xpd",
    "utzoeu4f2ut8vr23am9m",
    "cjzakginpgerudpzpylz",
    "n0l6fmpbzicsp0syryku",
    "yz4bs4jdqq6tuhwtqnub",
    "tq4fw5uaozwsj89ageog",
    "tcmu8i4t7bbddaqtv1o6",
    "bcwurrdkrljbtuvxh94q",
    "t9crk3wvhpoobhtgzje7",
    "mfecpvy9cnnc88jgt1ld",
    "gv4pkgkhwv8ykkuln2e5",
    "nj62wceuunq3pwdo8vrk",
    "bmen0wjjyc23plbxtpa1",
    "a3qqvkrdx2fljhdoafyn",
    "diiyf5mtsbqlglbh70ip",
    "jitdggs1tkm44oeumeg2",
    "wkdhf4ynzpyclazu2hen",
    "qhc9l5oxl9k2pwlmh32j",
    "dlblz4vrenwlccichhng",
    "sl2itl0x2ly294at3fmi",
    "fixmyss7fz5kogckbwps",
    "xqx2qggwhvxevtu02zpn",
    "zlza2ys8jp4tivqgxciz",
    "nch2u4rtlogzkukbvwao",
    "gw8khuzgyyrekiw2ter1",
    "hzsjuxdvub3adleejzim",
    "pppdfa2omyvld54vsl2l",
    "miissb54fwzdcnvwjbvg",
    "ibpd0ywdpmkqa9ipthx0",
    "g2qw7dswnztlrwvntcfi",
    "wz8f5czcgciswxjaoynx",
    "awn5ibvubswxc4vikgx0",
    "f6cycogpuyz8thknfmme",
    "nlqoicocc5kigmeekt3i",
    "tbtvyr7w4an2ighumwdg",
    "zihuogewdj6xcvd7up7j",
    "h5kifmtxhtkwwa6ntjzu",
    "wzulzaesdfkeltabmvpd",
    "yrbxqaftsvqbwl427jow",
    "rxxgdboyu70bo4trquf8",
    "ikhhdaoi7qkkz0mnclyw",
    "pxjeroetnm4brwytztxf",
    "a1uhlcxejdwssbb6zyc6",
    "pirlliby8dt98zlectzj",
    "lwz9gp9ly4xsxbjjexmw",
    "vmdjizg8rot5m9ihgfhj",
    "f5tzypp0ozghg97hceyh",
    "cg2pddt4cah6og5giaaa",
    "j3vrgzl5bytfsz0nhjgo",
    "uf3ot8ulcq9zjq5n7tap",
    "cim1lkzl6bwppjaten94",
    "zskp8hlthznjhmee0kgk",
    "zidcsynbqkqpf7ae1yk9",
  ],
  fashion: [
    "apt52alyumliboxtvzjl",
    "jfmos5fn39f3ty7vv3aq",
    "fjbqe3sxwnnljq7pzlrr",
    "rwhwit504yhmisrh0ya7",
    "jwmducf1xe9ghzvgmc8y",
    "x6jau5klpokawfp83o2c",
    "bdawtlkjnecjys1vigfu",
    "z4vkgosxqqx33gdspwup",
    "daoiechls1vsjrhzcfys",
    "oeo55oyalpuj2816u12m",
    "gmbrlhqgz1qpoiwterlg",
    "qdmmcjdlgqdjf29fgrhi",
    "gmdixijombsaiusz7zos",
    "tte43x6fsnymfvdcxejj",
    "wbeutpijtx8zow9vns24",
    "szxjsb5lcbzne1cpmbxl",
    "brdxncaxjla3w6z1uza9",
    "wvdjmccxh8qe4kp2tryr",
    "uad7yta78nxwu2blegfh",
    "m1fcvmxpxyh3gunpjurv",
    "pok6xh7hxktibg3sm5gl",
    "bp4oguswqbdup3rdxdfo",
    "gktpty0j3kfsmlayeh5o",
    "nzesinkooaqjcczpj18x",
    "q918bbi4isznwljvq2gd",
    "o5ksyfvjiowrtd82fqca",
    "kd8sekfgt8wewgbb8eea",
    "ufbxb8njluwxwle1afzc",
    "l8t38lczn3zv1yq9bool",
    "hglffui0d3cias59u2tx",
    "zk6ebp3r7sottau9hyoe",
    "p83berwagtbk9vgymq4b",
    "io5gcgawayrf0lyqj6i8",
    "evjspclydw1whtm3e42o",
    "xuzcsu5dfkij28tptmqi",
    "twbthysrakuvgtfneqk7",
    "e6lzvtrcglvmqln6wmuv",
    "gnscmqab5cx900tzd9ai",
    "zkyw9k7bjb0hkst6rvty",
    "o5vjf0nc39yrinml8dct",
    "jyguik9mv7wktopnzv8p",
    "gjmhs21fyk34xjoa0puz",
    "uvd2ptezubyj1vloi1q3",
    "mlzseubfmkatbjve7nmm",
    "xefe1gd1pfhb5fo9vsrq",
    "rivykorzxxccgyuyz1fw",
    "pscjtogwsywu4avfxzmh",
    "xzwruwqqrwi8db5anywb",
    "zaepoijuqy6czviz7src",
    "dbnbuog1ujbfg0d3cw80",
    "euz7ofgvlsza60ihxp1y",
    "oqhdr5ulfcbkdwtta7m9",
    "sjh2x6dlrbtmgzfbkkat",
    "lkyjsojzkpmngxkslpt9",
    "m0m99t43qncqsd349ens",
    "semsdbrplpslb22tzumc",
    "tvmz8szze9ephqrgct7o",
    "tlfu88ysohobyr8qqbsc",
    "puhyp8hmloczaqlcbpws",
    "nsdpxdstojq2ifg7remq",
    "mjez33fhbpjxtuazecsq",
    "smrhky6ylxs8cotsytbd",
    "lqhgclqvw2qvqxcnbffx",
    "ogaizrusccwy6dchysg7",
    "cdfvv7bs0a5bafhibr8y",
    "wagk5d9dhoesesdx15gn",
    "vdnb1im9crfz6liwskfz",
    "muc6kvfqhtgjj6iathpf",
    "mffwonvye5zyaaokndxb",
    "w8uzurmbie6iabxifpet",
    "uu2gedkwekuqmfy3ivwl",
    "aprvzn3hz4db5k5cedpc",
    "tmcu8ahvojbvt3bkbkse",
    "iqimunllwmnz8gzf0icx",
    "vaj4mmndnmjawc34ebxr",
    "ttpugmncluxhcje58n9u",
    "vjwvns3kjecsw0yr8tf1",
    "pinvdrzz3bvdatxbraqw",
    "y3hksc4cwdmenpa5t1tc",
    "chei8ajelx9qizfgdwvo",
    "edcnzuj5sjjox95euum4",
    "kwfngg93svg281mqlvtc",
    "bwdyiro4zvpjnpiazs6s",
    "r1ymtgs22m75l5eosa6l",
    "xgrn5z1ab2iuaskd7sxj",
    "mvilwblmcvpd7yi7arne",
    "rhb7d45zblyeka0rkh41",
    "nxbfd8o5anpvj7ts5me1",
    "r8cpc6ytgqvha2erh266",
    "t8wrdbkosk7zlp9jxdtz",
    "brr7dtrgui4a8v7pvyv7",
    "m0ydh4khjxe9w6lm2umr",
    "u41c54qqvsw4oojsruub",
    "cy36uijv2vf3sgynxetd",
    "qp66nxperujnarx0yjg6",
    "yx4hziiepq168bxqrp7w",
    "wkix0csv0jkywyxbq8oq",
    "ikz212e3pvkfvd6spedy",
    "bz5ecjtjt6pbfh3wztoh",
    "fwwc4avg3h0zlgpqirzc",
    "grfs8re7xed5bk1qw3ap",
    "zrxbrtj05r42pw6jjzss",
    "zyg2cr4ahckivrbmwae3",
    "aifehn4uvyyvmoarcjpg",
    "g38bev9qxxvnyi3fd4nt",
    "oyjxh7l3igi6lupvrutv",
    "v5xonrdg2fw94l1kc4xy",
    "r1pyvjkt32xl89zl8zzd",
    "wqkrna1td5x3ehyp0rch",
    "hc6trjwn0izkjgco5myo",
    "hoc5e0vczuh3belnpw5n",
    "y0rqhgesxrsbfrzjhz4q",
    "x6d8tivl7xukfxi6vrie",
    "dyaduaoool0jcrg7sqkw",
    "ywlodyoeytjgfpobkprt",
    "gzj69piqm7jyj1ijkkn7",
    "oxxfdqk1ddoe9avj4xul",
    "vq0nml4xyst6yvwghsiu",
    "ukhkyyjvgnppm3chzmxg",
    "o0orkfxqjf1xy7awtvlw",
    "pk33w5d8ur7uffmkvqfv",
    "v6jgpnvpdljfcmlfpqwg",
    "ki5txrs0xjvehruaiahu",
    "spkqrkpdcitqz4rznhzz",
    "ghvgtqipnyoedpcvtese",
    "twmaeicamsjmpktwrlow",
    "sbm0o5dfv4by5hrtzeg7",
    "opxdgk8xbosccsnu0s3a",
    "cbzlnxwrbwcmzunxk1j8",
    "rrbapyvhaqou2ca4qpdo",
    "dhch8kemrfwei2i6b8zg",
  ],
  finance: [
    "ogurgexzhcftdnf8jirb",
    "lnknxji9v8vy0vycrigm",
    "wcilt01rvob3ou1wfhj4",
    "x4kmfuf8tgpq4bjwc5zo",
    "bkdn1j3ic3bk2udj4jbr",
    "mrpbz4dhxxxem7ayzcvq",
    "gsstwem4rwlmiob1ytqp",
    "nqyvff5kx6w1v1b1nu8z",
    "lniwydp1szt163zcflmo",
    "y0khyxtzpqhd8e2jxtgq",
    "g976bpnvbrd2m9y2ocsa",
    "wzihlztwisibrzehllhi",
    "skgfpnqnbnyol4lxadtx",
    "tpygvowarijhxunmlqkd",
    "o26xzswnhb2vzpsztmkm",
    "be0j6bbxfaupisemdabm",
    "yubqdktqbtwsc3kzpobp",
    "q7zpbhquzdlvc45olfck",
    "abkxl0qbvpfquzr3uo2n",
    "deoqvobtsnmnegwircqi",
    "bseblc0rzdzpgczaz5cl",
    "diwzfeng6imbmbdx3sin",
    "eytvut6ckp9pfzfpytmi",
    "ak4fxkmxeyzhh41hdqld",
    "awiuvfxdmqtcye6ejvgu",
    "x1lz4elcqggct9bq0q50",
    "v4wj0212gc0rkx85srsk",
    "pgepqyeiefd1mn6cxmat",
    "lw1oaimygi4wbh9g9qpo",
    "ds2o6wfyqpnpvvlcwprv",
    "mg9n8ivisiqdvesykwdr",
    "p9yskznxr2ken9ndet0u",
    "zz2lfl0oyhunczi8yqdp",
    "atyipabsjmgcaqqtr2qb",
    "lrjoi8pw9mmryte69dai",
    "doofj2tlfwj6ehfp944t",
    "mcpiy7hpb5fc3txcrb15",
    "pwrowxgjfymowsogdwwt",
    "kz4ht0uriqboqmnrkpcs",
    "ajdvblxl9dyh3r0yrwdk",
    "odxtbenarl7t8g8jd6wb",
    "syfl8lmfftkwcpodlsky",
    "fvwkj1zymnj9usqgcu0r",
    "p9oh1fidw90mhbmogxgf",
    "h7bhxl4ikmu6qxrspg2o",
    "vdujgn4io0tnjkmfqinx",
    "wjnaumihphbdeeo3prxe",
    "xlxne2h2qobmusg8r7pp",
    "cimt93q6yqbozyqthg66",
    "ka9vhytykcdo7hcopzrv",
  ],
  food: [
    "tcs49h7wz23ibpehrpan",
    "rlbrmur04a7iuyrxjxdt",
    "akwissuleztkqeish0tf",
    "la5hu7qnoxlknhjrs8aj",
    "zdn4cscx8o7nvn59xkgt",
    "f7ui4doqdoyunfekwvqo",
    "syf2u8lbm9yz7kgyysp1",
    "qt5ejqoipixudvqsh4r1",
    "uxwlyjhcjqrnmu9cnqdm",
    "p74rdindalnkemlflrd5",
    "tzjggzpic8uwlr58yhy4",
    "qobjlzc4oq6xoxgr0fo3",
    "pvdehkxolis7lyrhp4cs",
    "nvo1xtw9rrb5cvh5al2s",
    "xjl4f08hysqsn203cvju",
    "eam9vapd19wgx6kb5jd8",
    "nmplghpuqbarlogpqzs9",
    "yltrb1ow8bulk2cwqed0",
    "c9w7de4xxgpvs0ipufca",
    "j81gg1hw6ignxjrb9dzk",
    "qysnj2lu1mcimfzrryzy",
    "rasf09dw4matib5itw35",
    "aobb4wmltgtu0b46toc5",
    "ftundibblvixttmfmgdx",
    "oo626oy4afyb8alylxwu",
    "lvmiyifhohdvmruf3rh4",
    "olqgkvvgsi18j7unkr1u",
    "xprirhzsijd8pjhg4zez",
    "lbnzwjmhsmbysacja5ky",
    "jfguzqtziqu7towyovjs",
    "gfsqi6p2e92s3h4o9ohk",
    "hanbpwgypcqwenh7nlib",
    "tjy9e3csy390ipqae8yk",
    "qqauwkolbuyepx1bjwkt",
    "x5gzna7uka4itvd9ztxa",
    "wwxefxnqepnkvuzulx7c",
    "ngpyjxhzmooeagkbfk5f",
    "s5dqtmrpt156ljtmxjxa",
    "aif92iqbnjovwthqbq1n",
    "qs0ocsbekfuw6j8loou2",
    "yv1wpavjt8peyitwdhnu",
    "qi4kmculowl5vak1sumr",
    "a21onnt37rbqltnxideh",
    "e5huiabqqefzngnkfmet",
    "vbknohatyvm3v8qtsodp",
    "vubyv6icklnywiiddueb",
    "emykg6zcvcvl6tdczfz3",
    "bex9kvxgrxwrarurcwds",
    "uspqdhqehsgf7aklmbip",
    "a65vwwx6ywczvpkj16bc",
    "jjfi0yta8nwc8g3fkrgv",
    "ajl9pggimazttdnldnpo",
    "bphtcgbpklixcdithh6b",
    "bgpoiq4v1uewewr8ugwp",
    "c1x7klujnvzgzigffzrw",
    "wimqmflh0h8pkfsuvebt",
    "rlosor3ali46kkbz6p3x",
    "szqmfajserzlngidwywa",
    "pxevc3xzru9oegcrtdkg",
    "cyjn2hr9l8b5opbg8gnk",
    "tzigk6zlnaakeupjvuer",
    "ri3dd8hzwtnjj2xveudd",
    "lsombqwkwqdn9aq1q5gl",
    "yliucnkjzjl52t9l7slh",
    "fwqoaejxzblhs12gwbww",
    "tymygislrlb9riymgehw",
    "ecbficrfepogqpefamns",
    "q269ppbt2epji6osysop",
    "jnbsob7pxiwkh0jg81nk",
    "rasszpebdnywoyuuanqq",
    "ouonkfkr9zj9xre7zpza",
    "iqqrs4kfpeavmvchmbrj",
    "cfzyks04yyfqgor3uxhq",
    "nej9mfr1bp2krgcmdawv",
    "qnys9ts5zhym0wmlarta",
    "e4s6nphnmhtjn0semzp0",
    "n1bo47kdfa7hnrw7hd5d",
    "hapbjsw79wxgl3ppl96n",
    "hqd32xh930hwftnuihog",
    "fq5kjw183eatiq40yaur",
    "we2yfi1vdn7zyhmamex9",
    "itzpjthpcjvlkwd2llgw",
    "iegmhny4dmqwutxnqluv",
    "aryn3haay3mzbr5t03wf",
    "nlq9sbqps6y0uogfl45h",
    "vk5r49geletebxuqbjkx",
    "tzg7ruxwmrruakrezjvd",
    "ttmln0nsvm4mmflelt0v",
    "np5ubsex4uvbeaa8aooq",
    "xxtav1oxwaq786tasxi2",
    "jbeqqwscuwmdxyu3yttz",
    "ojxhugg5rbp6yptwalfw",
    "ndvyiu0vwsvkeltg1z3h",
    "izng1pideecbirvknjz0",
    "vet5jqeszartgogkcznf",
    "k2buy60hzhccflmiceiy",
    "ew986hkaogxq1w4nnsik",
    "et1i1q1fwek4zqo2moj1",
    "wichcxtomxhsj6pybyna",
    "ngz5z1m1egupbif3ju1a",
  ],
  sport: [
    "eyw7azi8grmz02ibk9og",
    "cunk79g73nz3fz8phqw8",
    "cirprepqeg0791wvqozz",
    "fkwkaedyfaxfunhcibho",
    "qfpwkiizjsrqwmwy6nv4",
    "rrzo6wjvtvojxutijwyq",
    "ao1de9k1ogbnydv45vw9",
    "bmotfyj458t2iovttpco",
    "bjikrzoa6npwdupajymu",
    "yhxjcm5omslyn6kf73pu",
    "risyr2jl7eaawz2uhpez",
    "vs6chhchynkflzqzxihm",
    "f469oqkmb58y5wutxn6l",
    "ly0h0eewd1l74r8hb93r",
    "wqyu2xhzokq3mlxpoiva",
    "eoibbwdzfqrub5crs3lb",
    "snzppwqkwvvekodq3dbl",
    "exlsab1bgp9oo8f1wr3d",
    "baoluyyt8pxegsp5aplr",
    "iuuzrcqlizmpprvhhblf",
    "basmbhktt8tvjxcmofmo",
    "y6uivvotozrb9pgopej4",
    "ddtky7mgslniikslqy18",
    "cgair4weodhucggsaagc",
    "krrf1wiqjo6cxjxdptxq",
    "yytiitom3hbwr4cwlqps",
    "njzg7y0jlgnbwjhdfxsz",
    "cddu9znvedjukpf5znky",
    "y6m16b8npdxjugxp7a14",
    "iz54vpygspdymbekihrq",
    "tpdv4wjmilxgiyo78ifh",
    "si7iiyx4j7gw22cbirpf",
    "o37smso8y80qzirchsq0",
    "s7dpsiqsyqaj7vu8nawe",
    "a9aeoejkjjmxtm7llntv",
    "aqzgm9m6w4uj2udmuwr5",
    "vrcyrwybqqusrvxnydk0",
    "cjepazikidbj4awcwdfu",
    "onk2odfbthnegsfzwk5j",
    "lfy0kjto2nlhw55kdi0o",
    "twehxrjsg09ssl6xnyum",
    "ksrytg9whaanwxzj88sa",
    "ioxia7ejntxm0doglh9t",
    "eh6sqvrygdvihdkbpzts",
    "vmcubzq1hghtee19qmhd",
    "ft25jc97jtibcbeey43l",
    "o8znl1mrvwgxbzcvoj3u",
    "y1kfbi8m95yn57vhw6c1",
    "pewms9lmudlkryr2c1wm",
    "td5mjcuvugloczgmd0vr",
    "o5pzuzvuubtdbqfklduh",
    "fiaeictsznzioxvpgb24",
    "m3crwm1q73kkg6w1iz9n",
    "oy9vhe1bmyo64kklxjr9",
    "j3zglzzcoojvaffvhggs",
    "izhol3o7cx8u9u1bym27",
    "f1nj1scodrjgdbievibc",
    "mndsv6z3la2hq9fnxsi3",
    "vuil3vsnilzbsnyilqjz",
    "alf3x3yviiw7t2pejfjl",
    "wi4qcahdkdpsj3e7nm7s",
    "xbqwco6nl518rcbcjj3k",
    "sqmkvh1xyg1e74hkyuv9",
    "feskzihtvueymo8vphw8",
    "oi3psfdc53gbq7do9sm8",
    "r0r7nab3qrkiiuzwdpih",
    "heamex3fri8jemvdiikq",
    "njwyd4zjm87bl4bhjhfq",
    "dkznrzz6zz7slrj6oqzi",
    "fgcndiwhaont9yhqi1so",
    "fqhuptehzrel6gkklc8y",
    "osswf2hgqazfthcsyufy",
    "srmi0fqtqxeljp7afg1p",
    "ax71zc0efolpwiyatspf",
    "knyov1qxndp6sujq3xhc",
    "un5f0zuofbvd3xu1cyfw",
    "jmh9x1e8f7gfhxeburwz",
    "yuaikktqz4xmxkfpl4n8",
    "fg6rws7emulrkf5iphfn",
    "uxbrtfxqvphkytxi17o7",
    "c9ulbzassuysl6uccuzo",
    "wh4uwqtyjstmktzwhfac",
    "o4uxmwhe1vgdxcwgytwn",
    "umkgj76jdglhms5yop5a",
    "h1ywo6zqswghiwps27bd",
    "ed9scb6duzlzdrfxgihq",
    "a3ockluanjtr5tl1ioie",
    "jstw7fhmz6vyevy7qgas",
    "t8amygay4reavzqlnxsp",
    "rofhurgt9nspidzgtjrf",
    "t55xndhvglvyavhmwvw4",
    "g3ipsjxk2azdm1ot9f7u",
    "irl0ustinwtkkhgmvw4p",
    "d0uto3ycqqbudyju6rtv",
    "a9p4eeeykjzmocvegbqs",
    "ivwggbskwbmkkqjx7uxx",
    "a5tulqhub6zzcneethwu",
    "smjpcpynwthovdzrqspo",
    "bunoxdi2f5jgry91qobu",
    "wsgwygyjhqfgol8fuqfd",
    "jzgxpoqp1crgyfpdc5g5",
  ],

  beauty: [
    "eaygn4f9ugpfwuyforfx",
    "blx8xzeryrsbidogsqnb",
    "a81xlxlp5plwlkxyjqsf",
    "yspqpixzc3zzzzldfeki",
    "i6xqs4cixsvpwph42kby",
    "qxtbygxjtxdelwqzed6g",
    "ruizbgkicdjgfwa1yupb",
    "b4aknddkzikls1v5tjwi",
    "fhi7rv62s7uvw1ii8hjv",
    "irdppugx8kfnwnpwishi",
    "eer3ijq72s6etc7e6wbn",
    "kvlrchxj3zyecnvnuupj",
    "yrejamknifnotlx4so0x",
    "vd7hjivtbiohhxpl7rvg",
    "jodlscqerbqmc9kkumdb",
    "l1nlfkfuk0orueb3pqcv",
    "czqretxkec0wvjlhtypl",
    "wlkwne83p63lvwgnhztq",
    "zm71hkvrthzxehxpxtfd",
    "yi2tj5hlwsn29158lc29",
    "c7j5kdzhlxu6g58pxvbk",
    "dyc3rqzosng0avvt1ekc",
    "rhy9rpm9yrjptwljr6fi",
    "qjfvgdpakrqyygn37lef",
    "i5nh1w7wipwcda8rrecz",
    "mlpbpkyygjarygvqvjwk",
    "n7q2lqat50eb3jqljetf",
    "rhlhu7f6ugyff4dnzlir",
    "tcqrxryf7uuy3edkdind",
    "qotb4wl2nlxmzzzklrlj",
    "g51bagzlmfibipttvruf",
    "soxjnaoeqywo3e56iyec",
    "aj3ymsdqdsfohov367uj",
    "xddvzpxexxsam1estzd4",
    "kvwxbvvdxf6xlxaluxrw",
    "vjg1vwaso67yazjfq2gh",
    "bgeqmpmqldefpzridkmu",
    "q32tzwnorkmtgqtuiuhi",
    "euyejil3zq2ofoqdnje9",
    "wsy9spm67gbs9inazhfj",
    "sgjndpqks0emksfhhbv4",
    "ocrs6ty1uj4baovtyse7",
    "u1fez2dpybme4atab2yq",
    "fc61zgghur4tkchp9bsb",
    "zghtrg4wzxowiwod9yjh",
    "re4mc4e7fixkyechqmix",
    "qxvrq767xm28paubqd5c",
    "cq3hipb0hbbadg27dlxp",
    "vrxwvgsgvh690ef2vyna",
    "wv07geft7zdobw5isgmk",
    "k68xh06nfymj5iiorgtt",
    "yobagl0s0qu2jemw5osv",
    "kko6m6g0yu3go1of4fbd",
    "aighono4yjen5jye6nfy",
    "uge8zjqs5ucyfuaz6m8i",
    "vc4nlgb4uw8ze6a82rhu",
    "d83jzebggssncsz0yxzi",
    "jmizu0kq8pjeow61s8cm",
    "zahtvr2w0drwqjg0n6ff",
    "gkqkhzu6siqtqkcgkeh5",
    "tgtakm6n3ugeagjmztkd",
    "mualhmik0j9qoafvcqmp",
    "onm9sbrimsn4yh6njtxi",
    "jjltnu1egfsregb91gqp",
    "cebcoemzv8vdowxgrpda",
    "fg8m60roun2g4uxocpho",
    "np7rkvajorzrsrewbu7j",
    "okqzzuiae7am1zfdd09i",
    "cpzyl8iytw6or0xwgitx",
    "lbivu9nuxtiuweixapce",
    "cdo5rgljij7pconus3is",
    "ziznlk7dobeobmitzzd5",
    "xdoxcr1bupen095nglfx",
    "d1rkxl3uqmho41smhzyz",
    "djrzvsma63htsmk5859f",
    "m7eoxsbckxz8dw0wiklc",
    "kg9idhx88cwukjmwnrtv",
    "oucowtdbwhnmnamzpvdp",
    "lqcuqppgbjxottgpzz6u",
    "as1i9ddoaawkuwsw9exx",
    "taebs5q0ay5vl4imumuv",
    "zndy6q8zfemzrytompnw",
    "hfoijxb7nnq7cv2g0hio",
    "qor64c39pp8bzsiv33ls",
    "czump0jbk2wrkxxijath",
    "rmqj2jo4ewxf6xkjdalf",
    "ycnuzjyfope7biucql6q",
    "zg56ocjphwro3szy5zzm",
    "b1hbm4agfz0obzxfq38z",
    "xvehorrpzmzk7rxwlpgx",
    "jyuujr1qaht3du2mwuea",
    "goojll0vgnmgny4pyklp",
    "uhbfbsypjusthg44he0g",
    "hgsyr3bjkgqlekoigjuw",
    "ngeq9eb240mb5qwt8ftc",
    "dlbnxjgweigwu12xddhu",
    "csssu5mffo1d4ssceqvg",
    "j1ipmvqjqcaocjcy4uye",
    "s49gx8luhxeffrcdkhdf",
  ],
};

export function cloudinaryUrls(
  filenames: string[],
  options?: {
    folder?: keyof typeof images;
    transformation?: "bento_landscape" | "bento_square";
    format?: "jpg" | "png" | "webp";
  },
) {
  return filenames.map((filename) => cloudinaryUrl(filename, options));
}

export function cloudinaryUrl(
  filename: string,
  options?: {
    folder?: keyof typeof images;
    transformation?: "bento_landscape" | "bento_square";
    format?: "jpg" | "png" | "webp";
  },
) {
  const cloudinaryName = "dwvi5ctsp";

  const inferredFolder = Object.keys(images).find((folder) =>
    images[folder].includes(filename),
  );

  const folder = options?.folder
    ? `/${options.folder}`
    : inferredFolder
      ? `/${inferredFolder}`
      : "";
  const transformation = options?.transformation
    ? `/t_${options.transformation}`
    : "";
  const format = options?.format ?? "webp";

  return `https://res.cloudinary.com/${cloudinaryName}/image/upload${transformation}${folder}/${filename}.${format}`;
}

export function idFromCloudinaryUrl(url: string) {
  return url.match(/\/([^/]+)\.[^.]+$/)?.[1] || url;
}
