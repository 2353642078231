import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { fontPairs as allFontPairs } from "src/utils/typography/configs/fontPairs";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { openModalLoginIfNeeded } from "../components/ModalLogin";
import { useUser } from "./UserContext";
import { InspoEvent, track } from "../utils";
import { setTitleVariables } from "src/utils/typography/configs/titleFontConfig";
import { setBodyVariables } from "src/utils/typography/configs/bodyFontConfig";

interface FontsContextType {
  localFonts: any[];
  fetchLocalFonts: () => void;
  fontPairs: any[];
  fontPairIndex: number;
  setFontPairIndex: (index: number) => void;
  currentFontPair: any;
  handleFontPairChange: (change: "back" | "next" | "random") => void;
  title: any;
  body: any;
}

const FontsContext = createContext<FontsContextType | undefined>(undefined);

export function FontsProvider({ children }: { children: React.ReactNode }) {
  const { user } = useUser();

  const isMobile = useIsMobile();

  const fontPairs = useMemo(() => {
    const excludedFontPairsSlugs = [
      "ppcirka_nimbussans",
      "ginto_aeonikfono",
      "rubik_opensans",
      "plaid_moderat",
      "syncopate_robotoserif",
      "termina_epilogue",
      "clashdisplay_clashgrotesk",
      "margobeuys_dmsans",
      "clash_archivo",
      "oswald_tirotamil",
    ];

    if (isMobile) {
      return allFontPairs.filter(
        (pair) => !excludedFontPairsSlugs.includes(pair.slug),
      );
    }

    return allFontPairs;
  }, [isMobile]);

  const [fontPairIndex, setFontPairIndex] = useLocalStorage({
    key: "font_pair_index",
    defaultValue: 0,
  });

  const currentFontPair = fontPairs[fontPairIndex];
  const [localFonts, setLocalFonts] = useState<any>([]);

  const titleFont = useFontForFontContext(
    "title",
    fontPairs,
    fontPairIndex,
    localFonts,
    currentFontPair,
  );
  const bodyFont = useFontForFontContext(
    "body",
    fontPairs,
    fontPairIndex,
    localFonts,
    currentFontPair,
  );

  const fetchLocalFonts = useCallback(async () => {
    if (!("queryLocalFonts" in window)) return;

    // @ts-ignore
    const localFonts = await window
      // @ts-ignore
      .queryLocalFonts()
      .then((fonts: any) => {
        return fonts.reduce((acc: any, curr: any) => {
          acc.push(curr);
          if (acc.find((f: any) => f.family === curr.family)) return acc;
          return acc;
        }, [] as any[]);
      })
      .catch(async (err: any) => {
        console.error(err);
        return [];
      });

    setLocalFonts(localFonts);
  }, []);

  useEffect(() => {
    fetchLocalFonts();
  }, [fetchLocalFonts]);

  function handleFontPairChange(change: "back" | "next" | "random") {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "font.change",
      })
    ) {
      return;
    }

    let newIndex;
    switch (change) {
      case "back":
        newIndex =
          fontPairIndex === 0 ? fontPairs.length - 1 : fontPairIndex - 1;
        break;
      case "next":
        newIndex =
          fontPairIndex === fontPairs.length - 1 ? 0 : fontPairIndex + 1;
        break;
      case "random":
        newIndex = Math.floor(Math.random() * fontPairs.length);
        break;
    }

    setFontPairIndex(newIndex);
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }

  useHotkeys([
    [
      "ArrowLeft",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowleft",
          })
        ) {
          return;
        }
        track({
          event: "change_font",
          properties: { source: "keyboard", action: "back" },
        });
        handleFontPairChange("back");
      },
    ],
    [
      "ArrowRight",
      () => {
        if (
          openModalLoginIfNeeded({
            user,
            isMobile: false,
            source: "keyboard.arrowright",
          })
        ) {
          return;
        }
        track({
          event: "change_font",
          properties: { source: "keyboard", action: "next" },
        });
        handleFontPairChange("next");
      },
    ],
  ]);

  return (
    <FontsContext.Provider
      value={{
        localFonts,
        fetchLocalFonts,
        fontPairs,
        fontPairIndex,
        setFontPairIndex,
        currentFontPair,
        handleFontPairChange,
        title: titleFont,
        body: bodyFont,
      }}
    >
      {children}
    </FontsContext.Provider>
  );
}

export function useFonts() {
  const context = useContext(FontsContext);
  if (context === undefined) {
    throw new Error("useFonts must be used within a FontsProvider");
  }
  return context;
}

export function useFont(type: "heading" | "body") {
  const { title, body } = useFonts();
  return type === "heading" ? title : body;
}

function useFontForFontContext(
  type: "title" | "body",
  fontPairs: any[],
  fontPairIndex: number,
  localFonts: any[],
  currentFontPair: any,
) {
  const [font, setFont] = useState<any | undefined>(currentFontPair[type]);

  const [isLockedAt, setIsLockedAt] = useState<number | undefined>(undefined);
  const [isLockedAtFont, setIsLockedAtFont] = useState<string | undefined>(
    undefined,
  );
  const [isLocal, setIsLocal] = useState<boolean | undefined>(undefined);

  function setFontVariables(type: "title" | "body", font: any) {
    switch (type) {
      case "title":
        setTitleVariables(font);
        break;
      case "body":
        setBodyVariables(font);
        break;
    }
  }

  useEffect(() => {
    if (isLockedAt === undefined) {
      const font = fontPairs[fontPairIndex][type];
      setFontVariables(type, font);
    }
  }, [fontPairIndex, isLockedAt]);

  useEffect(() => {
    async function innerSetFontVariables() {
      if (isLockedAtFont !== undefined) {
        if (isLocal) {
          const localFont = await window
            // @ts-ignore
            .queryLocalFonts()
            .then((fonts: any) =>
              fonts.find((f: any) => f.family === isLockedAtFont),
            );
          if (localFont) {
            setFontVariables(type, {
              "readable-font-family": localFont.family,
              "font-family": localFont.family,
            });
          }
        } else {
          for (const pair of fontPairs) {
            if (pair[type]["readable-font-family"] === isLockedAtFont) {
              setFontVariables(type, pair[type]);
              break;
            }
          }
        }
      }
    }

    innerSetFontVariables();
  }, [isLocal, isLockedAtFont]);

  useEffect(() => {
    if (isLockedAtFont !== undefined && !isLocal) {
      for (const pair of fontPairs) {
        if (pair[type]["readable-font-family"] === isLockedAtFont) {
          setFont(pair[type]);
          return;
        }
      }
    }

    if (isLockedAtFont !== undefined && isLocal) {
      const localFont = localFonts.find(
        (f: any) => f.family === isLockedAtFont,
      );

      if (!localFont) return;

      const font = {
        "readable-font-family": localFont.family,
        "font-family": localFont.family,
        "foundry-url": "",
        source: "local",
      };
      setFont(font);
      return;
    }

    if (isLockedAt !== undefined) {
      const font = fontPairs[isLockedAt][type];
      setFont(font);
      return;
    }

    const font = currentFontPair[type];
    setFont(font);
  }, [currentFontPair[type], localFonts, isLocal, isLockedAt, isLockedAtFont]);

  return {
    font,
    setFont,
    isLockedAt,
    setIsLockedAt,
    isLockedAtFont,
    setIsLockedAtFont,
    isLocal,
    setIsLocal,
  };
}
