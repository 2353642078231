import { ActionIcon, type ActionIconProps } from "@mantine/core";
import React from "react";

const actionIconTheme = {
  transparent: {
    bg: "transparent",
    color: "black",
  },
  dark: {
    bg: "black",
    color: "white",
  },
  light: {
    bg: "white",
    color: "black",
  },
};

type ActionIconThemeType = keyof typeof actionIconTheme;

export const InspoActionIcon = React.forwardRef(
  (
    {
      theme = "dark",
      icon,
      onClick = () => {},
      ...props
    }: {
      theme: ActionIconThemeType;
      icon: React.ReactNode;
      onClick?: (e) => void;
    } & ActionIconProps,
    ref,
  ) => {
    return (
      <ActionIcon
        // @ts-ignore
        ref={ref}
        size={"3rem"}
        variant="transparent"
        c={actionIconTheme[theme].color}
        bg={actionIconTheme[theme].bg}
        opacity={props.disabled ? 0.5 : 1}
        radius={"xs"}
        onClick={(e) => onClick?.(e)}
        {...props}
      >
        {/* @ts-ignore */}
        {React.cloneElement(icon)}
      </ActionIcon>
    );
  },
);
