import { Popover, Text } from "@mantine/core";
import { useSessionStorage } from "@mantine/hooks";
import { IconDiamond, IconHome2 } from "@tabler/icons-react";
import { useState } from "react";
import { track } from "../utils";
import { FontPickerTabCurated } from "./FontPickerTabCurated";
import { FontPickerTabLocal } from "./FontPickerTabLocal";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoPickerTabButton } from "./ui/InspoPickerTabButton";
import { useUser } from "../contexts/UserContext";
import { useFont } from "../contexts/FontsContext";

export const FontPicker = ({
  type,
  setShow,
}: {
  type: "heading" | "body";
  setShow: (value: boolean) => void;
}) => {
  const { isPro } = useUser();
  const { font, isLocal } = useFont(type);
  const [browserCompatibilityPopoverOpen, setBrowserCompatibilityPopoverOpen] =
    useState(false);

  const [tab, setTab] = useSessionStorage<"curated" | "local">({
    key: {
      heading: `font_picker_tab_heading`,
      body: `font_picker_tab_body`,
    }[type],
    defaultValue: isLocal ? "local" : "curated",
  });

  return (
    <Vertical
      h={500}
      w={400}
      pos={"absolute"}
      bottom={70}
      ml={-100}
      p={"sm"}
      gap={"sm"}
      bg={"#323232"}
      style={{
        boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.20)",
        borderRadius: "var(--mantine-radius-md)",
        border: !isPro ? undefined : `2px solid #474444`,
        backdropFilter: "blur(15px)",
      }}
    >
      {/* {!isPro && <InspoOverlayPaywall openLifetimeModal={openLifetimeModal} />} */}

      <Horizontal noWrap gap={"xxs"} center fullW>
        <InspoPickerTabButton
          title="curated"
          icon={<IconDiamond />}
          selected={tab === "curated"}
          onClick={() => {
            track({
              event: "change_font_picker_tab",
              properties: { tab: "curated" },
            });
            setTab("curated");
          }}
        />

        <Popover
          width={200}
          position="bottom"
          withArrow
          shadow="md"
          opened={browserCompatibilityPopoverOpen}
          onChange={setBrowserCompatibilityPopoverOpen}
        >
          <Popover.Target>
            <InspoPickerTabButton
              title="local"
              icon={<IconHome2 />}
              selected={tab === "local"}
              onClick={() => {
                if ("queryLocalFonts" in window) {
                  track({
                    event: "change_font_picker_tab",
                    properties: { tab: "local" },
                  });
                  setTab("local");
                } else {
                  setBrowserCompatibilityPopoverOpen(true);
                }
              }}
            />
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="xs">
              Local font access is not available on this browser.
              <br />
              <br />
              Please use a Chromium-based browser like Chrome, Arc, Opera or
              Edge.
            </Text>
          </Popover.Dropdown>
        </Popover>
      </Horizontal>

      {tab === "curated" && (
        <FontPickerTabCurated type={type} setShow={setShow} />
      )}

      {tab === "local" && <FontPickerTabLocal type={type} setShow={setShow} />}
    </Vertical>
  );
};
