import {
  Icon123,
  IconBaguette,
  IconBallBaseball,
  IconBrandMastercard,
  IconCamera,
  IconChartArea,
  IconChartPie,
  IconChartPie2,
  IconClipboardTypography,
  IconCoffee,
  IconCoin,
  IconCoins,
  IconColorPicker,
  IconCookie,
  IconCreditCard,
  IconCricket,
  IconGenderFemale,
  IconGlass,
  IconGlassChampagne,
  IconGrillFork,
  IconHeadphones,
  IconInfoCircle,
  IconLeaf,
  IconNews,
  IconPennant,
  IconPennant2,
  IconPerfume,
  IconPlant2,
  IconRainbow,
  IconReceipt,
  IconReceiptTax,
  IconSearch,
  IconShoppingBag,
  IconShoppingCart,
  IconSoccerField,
  IconStar,
  IconStarsOff,
  IconSwimming,
  IconTags,
  IconTruckDelivery,
  IconWeight,
} from "@tabler/icons-react";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import React from "react";

type IconsGridContentProps = {
  icons: any[];
};

export const iconsGridContent = {
  iconFashion: {
    icons: [
      IconTags,
      IconShoppingBag,
      IconShoppingCart,
      IconStar,
      IconSearch,
      IconTruckDelivery,
    ],
  },

  iconEditorial: {
    icons: [
      IconNews,
      IconInfoCircle,
      IconClipboardTypography,
      IconBallBaseball,
      IconHeadphones,
      IconCamera,
    ],
  },

  iconFinance: {
    icons: [
      IconCoins,
      IconChartArea,
      IconCoin,
      IconBrandMastercard,
      IconReceiptTax,
      IconChartPie2,
    ],
  },

  iconFood: {
    icons: [
      IconGlass,
      IconCoffee,
      IconCookie,
      IconBaguette,
      IconGlassChampagne,
      IconGrillFork,
    ],
  },

  iconSport: {
    icons: [
      IconSoccerField,
      IconBallBaseball,
      IconCricket,
      IconPennant2,
      IconSwimming,
      IconWeight,
    ],
  },

  iconBeauty: {
    icons: [
      IconColorPicker,
      IconRainbow,
      IconPlant2,
      IconPerfume,
      IconGenderFemale,
      IconLeaf,
    ],
  },
} as const satisfies Record<string, IconsGridContentProps>;

export default function IconsGrid({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<IconsGridContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Horizontal
      fullW
      fullH
      center
      bg={colorScheme.background}
      px={"lg"}
      gap={"xxs"}
      {...props}
    >
      {content.icons.map((icon, index) =>
        React.createElement(icon, {
          key: index,
          strokeWidth: "xs",
          size: 32,
          color: colorScheme.textdark,
        }),
      )}
    </Horizontal>
  );
}
