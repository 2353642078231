import { Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import { CreateNewFolderModalContent } from "./CreateNewFolderModalContent";
import { FoldersModalContent } from "./FoldersModalContent";
import { FolderContent } from "./FolderContent";
import { EditFolderModalContent } from "./EditFolderModalContent";
import { modals, type ContextModalProps } from "@mantine/modals";
import { useFolders } from "../../contexts/FoldersContext";

export type SavedModalViews =
  | "folders"
  | "createNewFolder"
  | "editFolder"
  | "folder";

export type SavedFolder = {
  id: string;
  name: string;
  moodboards: string[];
};

export async function fetchMoodboards() {
  const response = await fetch("/api/moodboards_library");
  if (!response.ok) {
    throw new Error("Failed to fetch moodboards");
  }
  return response.json();
}

export async function updateFolder(folderData: { id: string; name: string }) {
  const response = await fetch("/api/moodboards_library", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "updateFolderName",
      folderId: folderData.id,
      folderName: folderData.name,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to update folder");
  }
  return response.json();
}

export async function updateMoodboards(folderId: string, moodboards: string[]) {
  const response = await fetch("/api/moodboards_library", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "updateMoodboards",
      folderId,
      moodboards,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to update moodboards");
  }
  return response.json();
}

export async function deleteFolder(folderId: string) {
  const response = await fetch(`/api/moodboards_library?folderId=${folderId}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error("Failed to delete folder");
  }
  return response.json();
}

export async function createFolder(
  folderName: string,
  currentMoodboardUrl?: string,
) {
  const response = await fetch("/api/moodboards_library", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "createFolder",
      folderName,
      moodboards: currentMoodboardUrl ? [currentMoodboardUrl] : undefined,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to create folder");
  }
  return response.json();
}

export function openModalSaved({
  step = undefined,
  shareUrl,
}: {
  step?: SavedModalViews;
  shareUrl?: string;
}) {
  const id = "saved";
  modals.openContextModal({
    modal: id,
    innerProps: {
      step: step,
      shareUrl: shareUrl,
    },
    onClose: () => modals.closeAll(),
    withCloseButton: false,
    size: "lg",
    styles: {
      body: {
        backgroundColor: "var(--mantine-color-gray-1)",
      },
      content: {
        backgroundColor: "var(--mantine-color-white)",
      },
    },
  });
}

export function ModalSaved({
  innerProps,
}: ContextModalProps<{
  step: SavedModalViews | undefined;
  shareUrl?: string;
}>) {
  const { folders, refetchFolders } = useFolders();

  const [step, setStep] = useState<SavedModalViews | undefined>(
    innerProps.step,
  );

  const [currentView, setCurrentView] = useState<SavedModalViews>();
  const [currentFolderData, setCurrentFolderData] =
    useState<SavedFolder | null>(null);
  const [editFolderData, setEditFolderData] = useState<SavedFolder | null>(
    null,
  );

  useEffect(() => {
    if (!step) {
      setCurrentView("folders");
      return;
    }

    setCurrentView(step);

    if (step?.startsWith("folder-")) {
      const folderId = step.split("-")[1];
      const folder = folders.find((folder) => folder.id === folderId);
      if (folder) {
        setCurrentView("folder");
        setCurrentFolderData(folder);
      }
      setStep(undefined);
      setCurrentView("folder");
    }
  }, [step, folders]);

  function handleBack() {
    switch (currentView) {
      case "folders":
        // do nothing, noop
        break;
      case "folder":
      case "editFolder":
      case "createNewFolder":
        // go back to folders
        setStep(undefined);
        setCurrentView("folders");
        break;
    }
  }

  function handleGoToCreateNewFolder() {
    setCurrentView("createNewFolder");
  }

  function handleGoToEditFolder(id: string) {
    const folder = folders.find((folder) => folder.id === id);
    if (!folder) return;
    setCurrentView("editFolder");
    setEditFolderData(folder);
  }

  function handleGoToFolder(id: string) {
    setCurrentView("folder");
    const folder = folders.find((folder) => folder.id === id);
    if (!folder) return;
    setCurrentFolderData({
      id,
      name: folder.name,
      moodboards: folder.moodboards,
    });
  }

  async function handleDeleteFolder(id: string) {
    await deleteFolder(id);
    await refetchFolders();
    handleBack();
  }

  async function handleCreateFolder(folderName: string) {
    await createFolder(folderName, innerProps.shareUrl);
    await refetchFolders();
    handleBack();
  }

  async function handleUpdateFolder(folderData: { id: string; name: string }) {
    await updateFolder(folderData);
    await refetchFolders();
    handleBack();
  }

  async function handleUpdateMoodboard(folderId: string, moodboards: string[]) {
    await updateMoodboards(folderId, moodboards);
    await refetchFolders();
    handleBack();
  }

  function handleClose() {
    modals.closeAll();
  }

  if (step && !currentView) {
    return;
  }

  return (
    <>
      {currentView === "folders" && (
        <FoldersModalContent
          folders={folders}
          handleClose={handleClose}
          handleGoToFolder={handleGoToFolder}
          handleGoToEditFolder={handleGoToEditFolder}
          handleGoToCreateNewFolder={handleGoToCreateNewFolder}
        />
      )}

      {currentView === "createNewFolder" && (
        <CreateNewFolderModalContent
          handleBack={handleBack}
          handleClose={handleClose}
          handleCreateFolder={handleCreateFolder}
        />
      )}

      {currentView === "editFolder" && editFolderData && (
        <EditFolderModalContent
          folder={editFolderData}
          handleBack={handleBack}
          handleClose={handleClose}
          handleUpdateFolder={handleUpdateFolder}
          handleDeleteFolder={handleDeleteFolder}
        />
      )}

      {currentView === "folder" && currentFolderData && (
        <FolderContent
          folder={currentFolderData}
          handleBack={handleBack}
          handleClose={handleClose}
          handleUpdateMoodboard={handleUpdateMoodboard}
        />
      )}
    </>
  );
}
