import {
  IconBaguette,
  IconCoffee,
  IconCookie,
  IconGlass,
  IconGlassChampagne,
  IconGrillFork,
} from "@tabler/icons-react";
import React from "react";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import { Vertical } from "../mantine-layout-components/Vertical";
import { Divider, Image, Space, Text, Title } from "@mantine/core";

type UnicornsContentProps = {
  title: string;
  subtitle: string;
  items: {
    imgs: string[];
    title: string;
    subtitle: string;
  }[];
};

export const unicornsContent = {
  unicorns1: {
    title: "Most valuable unicorns",
    subtitle: "Top 5 unicorns that received investments through AngelList",
    items: [
      {
        imgs: ["/assets/finance/unicorn03.png"],
        title: "Solana",
        subtitle: "$110B",
      },
      {
        imgs: ["/assets/finance/unicorn04.png"],
        title: "Celo",
        subtitle: "$100B",
      },
      {
        imgs: ["/assets/finance/unicorn05.png"],
        title: "Near",
        subtitle: "$90B",
      },
    ],
  },
} as const satisfies Record<string, UnicornsContentProps>;

export default function Unicorns({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<UnicornsContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      centerH
      px={"md"}
      {...props}
    >
      <Vertical gap={0}>
        <Title variant="card-headline-xs" c={colorScheme.textdark}>
          {content.title}
        </Title>

        <Space h={"xs"} />

        <Text variant="card-paragraph-md" c={colorScheme.textdark}>
          {content.subtitle}
        </Text>

        <Space h={"md"} />

        <Vertical fullW gap={"xs"}>
          {content.items.map((item, index) => (
            <React.Fragment key={item.title}>
              <Horizontal gap={0} fullW spaceBetween noWrap>
                <Text c={colorScheme.textdark} variant="card-label-lg" w={"16"}>
                  {index + 1}
                </Text>

                <Image src={item.imgs[0]} alt={item.title} h={24} />

                <Text
                  ml={"xs"}
                  variant={"card-paragraph-md"}
                  c={colorScheme.textdark}
                  flex={1}
                >
                  {item.title}
                </Text>

                <Text c={colorScheme.textdark} variant="card-label-lg">
                  {item.subtitle}
                </Text>
              </Horizontal>

              {index !== content.items.length - 1 && (
                <Divider
                  w={"100%"}
                  color={colorScheme.textdark}
                  opacity={0.5}
                />
              )}
            </React.Fragment>
          ))}
        </Vertical>
      </Vertical>
    </Vertical>
  );
}
