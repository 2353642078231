import { Button, type ButtonProps } from "@mantine/core";
import React from "react";

export function InspoPickerTabButton({
  icon,
  onClick = () => {},
  title,
  selected = false,
  ...props
}: {
  icon?: React.ReactNode;
  onClick?: () => void;
  title: string;
  selected?: boolean;
} & ButtonProps) {
  return (
    <Button
      w={"100%"}
      radius={"md"}
      h={32}
      mih={32}
      bg={selected ? "gray.5" : "transparent"}
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: selected ? "#464444" : "#6B6B6B",
        backdropFilter: "blur(15px)",
      }}
      styles={{
        label: {
          fontFamily: "JetBrains Mono",
          fontWeight: 700,
          fontSize: "var(--mantine-font-size-xs)",
          letterSpacing: "0.48px",
          textTransform: "uppercase",
        },
      }}
      leftSection={React.cloneElement(icon as any, {
        size: 16,
        color: "white",
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onClick?.();
      }}
      {...props}
    >
      {title}
    </Button>
  );
}
