export function track({ event, properties }) {
  // https://posthog.com/tutorials/event-tracking-guide#setting-up-custom-events

  // @ts-ignore
  window.posthog?.capture(event, properties);
}

export enum InspoEvent {
  RANDOM = "random_everything",
  OPEN_LOGIN_MODAL = "open_login_modal",
  SET_HISTORY_CHECKPOINT = "set_history_checkpoint",
}

export type IndustryType = (typeof industries)[number];

export const industries = [
  { slug: "foodNDrink", label: "Food & Drink", isPro: false },
  { slug: "ecommerce", label: "Fashion", isPro: false },
  { slug: "beauty", label: "Beauty", isPro: true },
  { slug: "editorial", label: "Editorial", isPro: true },
  { slug: "finance", label: "Finance", isPro: true },
  { slug: "sport", label: "Sport", isPro: true },
];

export function debounce(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;
  const debouncedFunction = function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };

  debouncedFunction.cancel = function () {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
}
