import {
  Icon123,
  IconBaguette,
  IconCoffee,
  IconCookie,
  IconGlass,
  IconGlassChampagne,
  IconGrillFork,
} from "@tabler/icons-react";
import {
  customizeTextVariantIfNeededForCardPreview,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import React from "react";
import { Box, Divider, Text, Title } from "@mantine/core";
import { Vertical } from "../mantine-layout-components/Vertical";

type MenuPdfsContentProps = {
  title: string;
  items: {
    title: string;
    label: string;
  }[];
};

export const menuPdfsContent = {
  menu1: {
    title: "Look what are we currently serving:",
    items: [
      {
        title: "Menu",
        label: "See PDF",
      },
      {
        title: "Wines",
        label: "See PDF",
      },
      {
        title: "Brunch",
        label: "See PDF",
      },
    ],
  },

  menu2: {
    title: "Our natural products",
    items: [
      {
        title: "Serum",
        label: "8 products",
      },
      {
        title: "Oils",
        label: "12 products",
      },
      {
        title: "Creams",
        label: "7 products",
      },
    ],
  },
} as const satisfies Record<string, MenuPdfsContentProps>;

export default function MenuPdfs({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<MenuPdfsContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  const CardDivider = (
    <Divider w={"100%"} color={colorScheme.textdark} opacity={0.5} />
  );

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      gap={"sm"}
      centerH
      px={"md"}
      {...props}
    >
      <Text variant="card-paragraph-sm" c={colorScheme.textdark} ta={"start"}>
        {content.title}
      </Text>

      {CardDivider}

      {content.items.map((el, i) => (
        <Vertical key={i} w={"100%"}>
          <Horizontal fullW spaceBetween align="start" mb={-8}>
            <Title
              variant={customizeTextVariantIfNeededForCardPreview(
                "card-headline-sm",
                titleFontFamily,
              )}
              c={colorScheme.textdark}
              ff={titleFontFamily}
              mt={-4}
            >
              {el.title}
            </Title>

            <Text
              variant={customizeTextVariantIfNeededForCardPreview(
                "card-label-md",
                bodyFontFamily,
              )}
              c={colorScheme.textdark}
              ff={bodyFontFamily}
            >
              {el.label}
            </Text>
          </Horizontal>

          {i !== content.items.length - 1 && CardDivider}
        </Vertical>
      ))}
    </Vertical>
  );
}
