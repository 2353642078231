import React, { useEffect, useState } from "react";
import Authors, { authorsContent } from "../components/bentoCards/Authors";
import {
  BentoHorizontalLg,
  BentoHorizontalMd,
  BentoSquareLg,
  BentoSquareMd,
} from "../components/bentoCards/BentoCards";
import BgPhoto, { bgPhotoContent } from "../components/bentoCards/BgPhoto";
import BlogArticleWithImageLeft, {
  blogArticleWithImageLeftContent,
} from "../components/bentoCards/BlogArticleWithImageLeft";
import BlogArticleWithImageLeftAndTagTop, {
  blogArticleWithImageLeftAndTagTopContent,
} from "../components/bentoCards/BlogArticleWithImageLeftAndTagTop";
import BlogArticleWithImageRight, {
  blogArticleWithImageRightContent,
} from "../components/bentoCards/BlogArticleWithImageRight";
import BlogArticleWithImageTop, {
  blogArticleWithImageTopContent,
} from "../components/bentoCards/BlogArticleWithImageTop";
import Cart, { cartContent } from "../components/bentoCards/Cart";
import EcommItem, {
  ecommItemContent,
} from "../components/bentoCards/EcommItem";
import HeadingWithBgColor, {
  headingWithBgColorContent,
} from "../components/bentoCards/HeadingWithBgColor";
import HeadingWithBgPhoto, {
  headingWithBgPhotoContent,
} from "../components/bentoCards/HeadingWithBgPhoto";
import IconsGrid, {
  iconsGridContent,
} from "../components/bentoCards/IconsGrid";
import MenuPdfs, { menuPdfsContent } from "../components/bentoCards/MenuPdfs";
import ProductSingle, {
  productSingleContent,
} from "../components/bentoCards/ProductSingle";
import Savings, { savingsContent } from "../components/bentoCards/Savings";
import TagsGrid, { tagsGridContent } from "../components/bentoCards/TagsGrid";
import TextLinesWithUpdatesTag, {
  textLinesWithUpdatesTagContent,
} from "../components/bentoCards/TextLinesWithUpdatesTag";
import TitleWithBgColor, {
  titleWithBgColorContent,
} from "../components/bentoCards/TitleWithBgColor";
import Unicorns, { unicornsContent } from "../components/bentoCards/Unicorns";
import { useColorPalette } from "../contexts/ColorSchemeContext";
import { useIndustry } from "../contexts/IndustryContext";
import { useCardsState } from "../contexts/CardsStateContext";

export function useBentoCards() {
  const { industry } = useIndustry();
  const { currentColorScheme: colorScheme } = useColorPalette();
  const { visibleCardsKeys } = useCardsState();

  function getVisibleChildrenCard({ cards }: { cards?: React.ReactNode[] }) {
    const visibleCard = cards?.find((card) => {
      return (
        React.isValidElement(card) &&
        card.key !== null &&
        visibleCardsKeys?.includes(card.key)
      );
    });

    if (
      !visibleCardsKeys ||
      !visibleCardsKeys.length ||
      !React.isValidElement(visibleCard)
    ) {
      const firstCard = cards?.[0] as React.ReactElement;
      return React.cloneElement(firstCard, {
        alternativeCardsKeys: cards?.map((c) => c?.["key"]).filter(Boolean),
      });
    }

    const alternativeCardsKeys = cards?.map((c) =>
      React.isValidElement(c) ? c.key : null,
    );
    return React.cloneElement(visibleCard, {
      // @ts-ignore
      alternativeCardsKeys: alternativeCardsKeys?.filter(Boolean) as string[],
    });
  }

  const bentoCards = {
    ecommerce: [
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <EcommItem
              key={"a1b2c3d4e5"}
              colorScheme={colorScheme.light}
              content={ecommItemContent.ecomm1}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Cart
              key={"f6g7h8i9j0"}
              colorScheme={colorScheme.bright}
              content={cartContent.cart1}
            />,
            <Cart
              key={"01JA52Z0ZRJ2WCATDFVFTBYVR6"}
              colorScheme={colorScheme.bright}
              content={cartContent.cart2}
            />,
            <TextLinesWithUpdatesTag
              key={"01JA5V7HNB6YE9TEM54FNKKJTV"}
              colorScheme={colorScheme.bright}
              content={textLinesWithUpdatesTagContent.list1}
            />,
            <BlogArticleWithImageTop
            key={"334ba411"}
            colorScheme={colorScheme.bright}
            content={blogArticleWithImageTopContent.blog1}
          />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"k1l2m3n4o5"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.title1}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"p6q7r8s9t0"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <TextLinesWithUpdatesTag
              key={"u1v2w3x4y5"}
              colorScheme={colorScheme.dark}
              content={textLinesWithUpdatesTagContent.list1}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"z6a7b8c9d0"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconFashion}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"e1f2g3h4i5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionSquare}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"j6k7l8m9n0"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading4}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"o1p2q3r4s5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.fashionLandscape}
            />,
          ],
        })}
      />,
    ],
    
    editorial: [
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"a1b2c3d4e5"}
              colorScheme={colorScheme.bright}
              content={titleWithBgColorContent.title2}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Authors
              key={"f6g7h8i9j0"}
              colorScheme={colorScheme.dark}
              content={authorsContent.authors1}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeftAndTagTop
              key={"k1l2m3n4o5"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftAndTagTopContent.food1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"p6q7r8s9t0"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"u1v2w3x4y5"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading2}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <TagsGrid
              key={"z6a7b8c9d0"}
              colorScheme={colorScheme.bright}
              content={tagsGridContent.tags1}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"e1f2g3h4i5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialSquare}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"j6k7l8m9n0"}
              colorScheme={colorScheme.dark}
              content={iconsGridContent.iconEditorial}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"o1p2q3r4s5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.editorialLandscape}
            />,
          ],
        })}
      />,
    ],

    finance: [
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"t6u7v8w9x0"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.title3}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Unicorns
              key={"y1z2a3b4c5"}
              colorScheme={colorScheme.bright}
              content={unicornsContent.unicorns1}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"d6e7f8g9h0"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"i1j2k3l4m5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageRight
              key={"n6o7p8q9r0"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageRightContent.article1}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"s1t2u3v4w5"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeSquare}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"x6y7z8a9b0"}
              colorScheme={colorScheme.dark}
              content={iconsGridContent.iconFinance}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Savings
              key={"c1d2e3f4g5"}
              colorScheme={colorScheme.bright}
              content={savingsContent.saving1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"h6i7j8k9l0"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.financeLandscape}
            />,
          ],
        })}
      />,
    ],

    foodNDrink: [
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageTop
              key={"334ba411"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageTopContent.blog1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"e022d8b3"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.title4}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"ccd57935"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.article1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"3f7364ca"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.foodLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"48a9"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading3}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <MenuPdfs
              key={"4511"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.menu1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgColor
              key={"b6ba"}
              colorScheme={colorScheme.light}
              content={headingWithBgColorContent.heading1}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"837e4801a790"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconFood}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"c651f984"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.foodSquare}
            />,
          ],
        })}
      />,
    ],

    sport: [
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"e4852c84"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <Authors
              key={"7255e263"}
              colorScheme={colorScheme.dark}
              content={authorsContent.authors2}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"f588a7ea"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading5}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"6f615f18"}
              colorScheme={colorScheme.bright}
              content={titleWithBgColorContent.title5}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"0b1bf232"}
              colorScheme={colorScheme.light}
              content={blogArticleWithImageLeftContent.article2}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"9a83dd9s"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportLandscape}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageTop
              key={"3303af1b"}
              colorScheme={colorScheme.dark}
              content={blogArticleWithImageTopContent.blog2}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"3e8eeaca"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconSport}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"15a2f581"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.sportSquare}
            />,
          ],
        })}
      />,
    ],

    beauty: [
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <BlogArticleWithImageLeft
              key={"bcb7fa7d"}
              colorScheme={colorScheme.bright}
              content={blogArticleWithImageLeftContent.article3}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <TitleWithBgColor
              key={"ffc62b6b"}
              colorScheme={colorScheme.dark}
              content={titleWithBgColorContent.title6}
              // @ts-ignore
              forceShowUploadImage={true}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <ProductSingle
              key={"e8c1ac6da873"}
              colorScheme={colorScheme.light}
              content={productSingleContent.product1}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"10c7972a"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.beautyLandscape}
            />,
          ],
        })}
      />,
      <BentoHorizontalMd
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgColor
              key={"80e696ca"}
              colorScheme={colorScheme.light}
              content={headingWithBgColorContent.heading2}
            />,
          ],
        })}
      />,
      <BentoSquareLg
        children={getVisibleChildrenCard({
          cards: [
            <MenuPdfs
              key={"45eb"}
              colorScheme={colorScheme.dark}
              content={menuPdfsContent.menu2}
            />,
          ],
        })}
      />,
      <BentoHorizontalLg
        children={getVisibleChildrenCard({
          cards: [
            <HeadingWithBgPhoto
              key={"c8bbaabbfdd3"}
              colorScheme={colorScheme.light}
              content={headingWithBgPhotoContent.heading6}
            />,
          ],
        })}
      />,

      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <IconsGrid
              key={"c6296262"}
              colorScheme={colorScheme.bright}
              content={iconsGridContent.iconBeauty}
            />,
          ],
        })}
      />,
      <BentoSquareMd
        children={getVisibleChildrenCard({
          cards: [
            <BgPhoto
              key={"662cf277"}
              colorScheme={colorScheme.light}
              content={bgPhotoContent.beautySquare}
            />,
          ],
        })}
      />,
    ],
  };

  const industryCards = bentoCards[industry.slug];

  return industryCards;
}
