import { Stack, type StackProps } from "@mantine/core";
import { forwardRef } from "react";
import { commonProps, type CommonProps } from "../commonProps";

export type VerticalProps = StackProps & CommonProps;

export const Vertical: React.FC<VerticalProps> = forwardRef<any, VerticalProps>(
  (props, ref) => {
    const {
      alignEnd,
      center,
      centerH,
      centerV,
      children,
      debug,
      fullH,
      fullW,
      noWrap,
      scrollable,
      spaceBetween,
      style,
      h,
      w,
      ...rest
    } = props;

    return (
      <Stack
        ref={ref}
        style={[
          // @ts-ignore
          {
            alignItems: "flex-start",
            ...commonProps(props),
            ...(center && {
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }),
          },
          style,
        ]}
        {...(centerV && {
          align: "center",
        })}
        {...(centerH && {
          justify: "center",
        })}
        {...rest}
        h={h}
        w={w}
      >
        {children}
      </Stack>
    );
  },
);
