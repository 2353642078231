import { ActionIcon, Text, TextInput } from "@mantine/core";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { IconArrowLeft, IconX } from "@tabler/icons-react";
import { InspoButton } from "../ui/InspoButton";
import { useState } from "react";

export function CreateNewFolderModalContent({
  handleBack,
  handleClose,
  handleCreateFolder,
}: {
  handleBack: () => void;
  handleClose: () => void;
  handleCreateFolder: (folderName: string) => void;
}) {
  const [folderName, setFolderName] = useState("");

  const isValid = folderName?.length > 0;

  return (
    <Vertical fullW gap="md">
      <Horizontal fullW spaceBetween align="center" mb="md">
        <ActionIcon variant="subtle" size={48} onClick={handleBack}>
          <IconArrowLeft size={24} />
        </ActionIcon>
        <Text variant="paragraph-lg">Create new folder</Text>
        <ActionIcon variant="subtle" size={48} onClick={handleClose}>
          <IconX size={24} />
        </ActionIcon>
      </Horizontal>

      <Horizontal
        noWrap
        bg={"white"}
        py={"xs"}
        px={"xs"}
        maw={"100%"}
        style={{
          borderRadius: "var(--mantine-radius-xs)",
        }}
        w={"100%"}
      >
        <TextInput
          variant="unstyled"
          placeholder="Folder name"
          ml={"xs"}
          mx={"auto"}
          bg={"white"}
          w={"100%"}
          autoFocus
          value={folderName}
          onChange={(event) => setFolderName(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (!isValid) {
              return;
            }

            if (event.key === "Enter") {
              handleCreateFolder(folderName);
            }
          }}
          styles={{
            wrapper: {
              border: "0px",
            },
          }}
        />
      </Horizontal>

      <InspoButton
        theme="dark"
        fullWidth
        disabled={!isValid}
        onClick={() => {
          if (!isValid) {
            return;
          }
          handleCreateFolder(folderName);
        }}
      >
        Create
      </InspoButton>
    </Vertical>
  );
}
