import { Group, Select, Text, type SelectProps } from "@mantine/core";
import { useRef } from "react";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { InspoBadge } from "./InspoBadge";
import { industries } from "../../utils";
import { useUser } from "../../contexts/UserContext";

export const InspoSelect: React.FC<{
  providedData: string[];
  providedValue: string;
  onClick: (el: string) => void;
}> = ({ providedData, providedValue, onClick }) => {
  const { isPro } = useUser();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Select
      ref={ref as any}
      data={providedData}
      value={providedValue}
      renderOption={({ option, checked }) => {
        return (
          <Horizontal h={"20px"} fullW spaceBetween gap={"xs"}>
            <Text variant="paragraph-md" fw={checked ? "bold" : "normal"}>
              {option.label}
            </Text>
            {!isPro &&
              industries.find((industry) => industry.label === option.value)
                ?.isPro && <InspoBadge theme="purple">PRO</InspoBadge>}
          </Horizontal>
        );
      }}
      onChange={(val) => {
        ref.current?.blur();
        if (!val) return;
        onClick(val);
      }}
      variant="unstyled"
      label={undefined}
      description={undefined}
      placeholder={undefined}
      withCheckIcon={false}
      radius={"xs"}
      styles={{
        root: {
          backgroundColor: "white",
          borderRadius: "var(--mantine-radius-xs)",
          height: "44px",
        },
        wrapper: {
          marginTop: "var(--mantine-spacing-xxs)",
        },
        input: {
          paddingLeft: "var(--mantine-spacing-xs)",
          width: `${Math.min(
            30,
            providedData.reduce((a, b) => (a.length > b.length ? a : b))
              .length + 5,
          )}ch`,
        },
        option: {
          border: "0px",
          marginTop: "-var(--mantine-spacing-xs)",
        },
      }}
    />
  );
};
